import React from 'react';
import {MaintenanceProvider} from '../services/dashboard/maintenance/maintenanceContext'

const MaintenanceLayout = (props) =>{
    return(
        <>
        <MaintenanceProvider>
        <div className="maintenance-layout">
            {props.children}
        </div>
        </MaintenanceProvider>
        </>
    )
}

export default MaintenanceLayout;