import React, { useState, useEffect, useContext } from 'react'
import { Button, showToast } from '../../../../../../layout';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox } from '@mui/material';
import UtilityPanelbox from './UtilityPanelbox';
import RateSchedules from './RateSchedules';
import GenabilityTable from './GenabilityTable';
import { Autocomplete, Stack, TextField } from '@mui/material'
import RateCalculationTable from './RateCalculationTable';
import { getAllConsumptionData, getTraiffs, getTerritories, setTariff, setTerritory, updateAccountProperties, getConsumptionData, updateConsumptionData, updateKwAdjustment } from "../../../../../../services";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { DashboardContext } from '../../../../../../services/dashboard/dashboardContext';
import { isEmpty } from 'lodash';
import {useParams} from 'react-router-dom';



const Consumption = () => {
  const context = useContext(DashboardContext);
  const projectId = useParams();
  const [consumptionData, setConsumptionData] = useState({});
  const [utilityRateId, setUtilityRateId] = useState(null);
  const [tariffId, setTariffId] = useState();
  const [utilityDropdownValue, setUtilityDropdownValue] = useState(null);
  const [tariffDropdownValue, setTariffDropdownValue] = useState(null);
  const [zoneDropdownValue, setZoneDropdownValue] = useState(null);
  const [anualKwh, setAnualKwh] = useState({
    value: null,
    validataion: false
  });
  const [kwhAdjustment, setkwhAdjustment] = useState({
    value: null,
    validataion: false
  });
  const [utilityDropdownData, setUtilityDropdownData] = useState([]);
  const [tariffDropdownData, setTariffDropdownData] = useState([]);
  const [zoneDropdownData, setZoneDropdownData] = useState([]);
  const [rateScheduleToggle, setRateScheduleToggle] = useState({
    hasTieredRates: undefined,
    hasTimeOfUseRates: undefined,
  });
  const [accountProperties, setAccountProperties] = useState({})
  const [panelValue, setPanelValue] = useState('');
  const [submitUserDataFlag, setSubmitUserDataFlag] = useState(false);
  const [annualkWhValue, setAnnualKwhValue] = useState(null);
  const [consumptionInfo, setConsumptionInfo] = useState({});
  const [rateSchedules, setRateSchedules] = useState([]);
  const [fee, setFee] = useState({
    monthlyFee: null,
    monthlyCharge: null
  })
  const [value, setValue] = useState(0);
  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getTariff = (lseId) => {
    let data = [];
    getTraiffs(projectId.id, lseId).then((response) => {
      if (response.data.success) {
        (response.data.data)?.map((item) =>
          data.push({
            tariff_id: item.tariff_id,
            name: item.tariff_name,
            utility_rate_id: item.id,
            value: item.tariff_name,
            masterTariffId: item.master_tariff_id
          }));
        setTariffDropdownData(data);
      }
    })
  }

  const setTariffs = (id,utilityRateId) => {
    let data = [];
    setTariff(id, { utility_rate_id: utilityRateId }).then((response) => {
      if (response.data.success) {
        let acntProp = {};
        let tariffData = {};
        (JSON.parse(response.data.data.properties)).forEach((item) => {
          if (acntProp) {
            acntProp = { ...acntProp, [item.keyName]: false }
          }
        });
        setRateSchedules(JSON.parse(response.data.data.rates));
        setFee({ monthlyCharge: response.data.data.min_monthly_charges, monthlyFee: response.data.data.monthly_fee })
        setAccountProperties(acntProp);
        tariffData = JSON.parse(response.data.data.tariff_data);
        setRateScheduleToggle({
          hasTieredRates: tariffData.hasTieredRates,
          hasTimeOfUseRates: tariffData.hasTimeOfUseRates
        })
        if (tariffData.hasTieredRates) {
          setZoneDropdownValue(null);
          getTerritories(projectId.id, tariffId).then((response) => {
            if (response.data.success) {
              (response.data.data)?.map((item) =>
                data.push({
                  value: item.territory_name,
                  name: item.territory_name,
                  territory_id: item.id,
                  zone_id: item.territory_id
                }));
              setZoneDropdownData(data);
            }
          })
        } else if (tariffData.hasTimeOfUseRates) {
          setZoneDropdownValue(null);
        }
      }
    })
  }

  const getZones = (tariffId) => {
    let data = [];
    getTerritories(projectId.id, tariffId).then((response) => {
      if (response.data.success) {
        (response.data.data)?.map((item) =>
          data.push({
            value: item.territory_name,
            name: item.territory_name,
            territory_id: item.id,
            zone_id: item.territory_id
          }));
        setZoneDropdownData(data);
      }
    })
  }

  useEffect(() => {
    getAllConsumptionData(projectId.id).then((response) => {
      if (response) {
        setConsumptionInfo(response[0].data.data);
        setConsumptionData(response[1].data.data[0]);
        let utilityData = response[2].data.data.map((item) => ({
          lse_id: item.lse_id,
          name: item.lse_name,
          value: item.lse_name
        }));
        if(utilityData.length>0){
          getTariff((utilityData)[0]?.lse_id);
        }
        setUtilityDropdownData(utilityData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    let tariffData;
    let metadata;
    if (!isEmpty(consumptionInfo)) {
      setTariffId(consumptionInfo?.lse_id);
      getTariff(consumptionInfo?.lse_id);
      tariffData = JSON.parse(consumptionInfo.tariff_data);
      metadata = JSON.parse(consumptionInfo.metadata);
      setUtilityDropdownValue(tariffData?.lseName);
      setTariffDropdownValue(tariffData?.tariffName);
      if (tariffData?.hasTieredRates) {
        setZoneDropdownValue(consumptionInfo?.territory_name);
        getZones(consumptionInfo?.lse_id);
      }
      setRateScheduleToggle({
        hasTieredRates: tariffData?.hasTieredRates,
        hasTimeOfUseRates: tariffData?.hasTimeOfUseRates
      });
      setUtilityRateId(consumptionInfo.utility_rate_id)
      setRateSchedules(JSON.parse(consumptionInfo?.rates));
      setFee({ monthlyCharge: consumptionInfo?.min_monthly_charges, monthlyFee: consumptionInfo?.monthly_fee })
      if (!isEmpty(metadata?.selected_properties)) {

        setAccountProperties(metadata?.selected_properties);
      } else {
        if (!isEmpty(consumptionInfo?.properties)) {
          let obj = {};
          (JSON.parse(consumptionInfo?.properties)).forEach((item) => {
            obj = { ...obj, [item.keyName]: false }
          });
          setAccountProperties(obj);
        }
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionInfo])

  useEffect(() => {
    let granularity;
    if (!isEmpty(consumptionData)) {
      granularity = consumptionData.granularity.replace(/^./, consumptionData.granularity[0].toUpperCase());
      setPanelValue(granularity);
      setkwhAdjustment({ value: consumptionData.metadata.kwhAdjustment })
      if (consumptionData.granularity === 'annual') {
        setAnnualKwhValue(consumptionData.user_data[0].quantityValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionData])

  useEffect(() => {
    if (panelValue !== '' && panelValue.toLowerCase() !== consumptionData?.granularity) {
      context.setConsumptionUserDataLength(null);

    } else if (panelValue !== '' && panelValue.toLowerCase() === consumptionData?.granularity) {
      context.setConsumptionUserDataLength((consumptionData?.user_data).length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelValue, consumptionData])


  useEffect(() => {
    if (
      panelValue !== "" &&
      panelValue.toLowerCase() !== consumptionData?.granularity
    ) {
      context.setConsumptionUserDataLength(null);

      context.setGenabilityCount({ totalCost: null, kwhSum: null });
    } else if (
      panelValue !== "" &&
      panelValue.toLowerCase() === consumptionData?.granularity
    ) {
      context.setConsumptionUserDataLength(consumptionData?.user_data.length);
      context.setGenabilityCount({
        totalCost: consumptionData?.total_cost,
        kwhSum: consumptionData?.metadata.total_kwh,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelValue, consumptionData]);


  const intervalOptions = [
    {
      name: "Monthly",
      value: "Monthly",
    },
    {
      name: "Annual",
      value: "Annual",
    },
  ];

  //This {submitUserData} function is to handle the submission of userData which is entered manually by the user
  const submitUserData = (termuserData) => {
    let granularity;
    setSubmitUserDataFlag(true);
    let obj = { granularity: panelValue ? (panelValue).toLowerCase() : '', readingData: termuserData ? termuserData : [] }
    if (termuserData.length > 0 && !isEmpty(obj.granularity)) {
      updateConsumptionData(projectId.id, obj).then((response) => {
        if (response.data.success) {
          setConsumptionData(response.data.data)
          context.setConsumptionUserDataLength((response.data.data.user_data).length);
          granularity = response.data.data.granularity.replace(/^./, response.data.data.granularity[0].toUpperCase());
          setPanelValue(granularity)
        } else if (response.data.success === false) {
          showToast('ERROR', `${response.data.message}`)
        }
      })
    } else if (isEmpty(obj.granularity) && termuserData.length > 0) {
      showToast('ERROR', `Please select valid Interval`)
    } else if (termuserData.length < 1 && !isEmpty(obj.granularity)) {
      showToast('ERROR', `Please enter valid User data`)
    } else if (isEmpty(obj.granularity) && termuserData.length < 0) {
      showToast('ERROR', `Please select valid Interval and User data`)
    }
  };

  const submitAnualKwhAdjustment = () => {
    let currentDate = new Date();

    // Get the year one year ago from the current date
    let oneYearAgoYear = currentDate.getFullYear() - 1;

    // Get the last date of previous month from current date
    const prevMonthLastDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const year = prevMonthLastDate.getFullYear();
    const month = ("0" + (prevMonthLastDate.getMonth() + 1)).slice(-2);
    const day = ("0" + prevMonthLastDate.getDate()).slice(-2);
    const formattedPrevMonthLastDate = year + "-" + month + "-" + day;

    // Create a new Date object for one year ago
    let oneYearAgoDate = new Date(oneYearAgoYear, currentDate.getMonth());
    const year1 = oneYearAgoDate.getFullYear();
    const month1 = ("0" + (oneYearAgoDate.getMonth() + 1)).slice(-2);
    const day1 = ("0" + oneYearAgoDate.getDate()).slice(-2);
    const formattedOneYearAgoDate = year1 + "-" + month1 + "-" + day1;


    let obj = {
      granularity: panelValue ? (panelValue).toLowerCase() : '', readingData: [{
        "fromDateTime": formattedOneYearAgoDate,
        "toDateTime": formattedPrevMonthLastDate,
        "quantityUnit": "kWh",
        "quantityValue": anualKwh.value ? parseInt(anualKwh.value) : null
      }]
    }

    if (anualKwh.value > 0) {
      updateConsumptionData(projectId.id, obj).then((response) => {
        if (response.data.success) {
          let granularity;
          setConsumptionData(response.data.data)
          context.setConsumptionUserDataLength((response.data.data.user_data).length);
          granularity = response.data.data.granularity?.replace(/^./, response.data.data.granularity[0].toUpperCase());
          setPanelValue(granularity)
        }
      })
    } else if (anualKwh.value <= 0) {
      showToast('ERROR', `Annual kWh should not be Zero or Lesser`)
    }
  };

  const submitKwAdjustment = () => {
    updateKwAdjustment({
      "projectId": projectId.id,
      "monthlyKwhAdjustment": kwhAdjustment.value
    }).then((response) => {
      if (response.data.success) {
        setConsumptionData(response.data.data)
        context.setConsumptionUserDataLength((response.data.data.user_data).length);
        setkwhAdjustment({ value: response.data.data.metadata.kwhAdjustment })
        if (response.data.data.granularity === 'annual') {
          setAnnualKwhValue(response.data.data.user_data[0].quantityValue)
        }
      }
    })
  }

  const SwitchhandleChange = (keyname) => {
    const newAccountProperties = { ...accountProperties, [keyname]: !accountProperties[keyname] };
    setAccountProperties(newAccountProperties)
  };
  const saveAccountProperties = () => {
    let obj = {}
    obj = { properties: accountProperties ? accountProperties : {} }
    updateAccountProperties(projectId.id, obj).then((response) => {
      if (response.data.success) {
        setFee({ monthlyCharge: response.data.data.min_monthly_charges, monthlyFee: response.data.data.monthly_fee })
        getConsumptionData(projectId.id).then((response) => {
          let granularity;
          let newObject = {};
          if (response.data.success) {
            // eslint-disable-next-line array-callback-return
            (response.data.data).map(item => {
              newObject[item.granularity] = item;
            })
            setConsumptionData(response.data.data[0])
            granularity = response.data.data[0].granularity.replace(/^./, response.data.data[0].granularity[0].toUpperCase());
            setPanelValue(granularity);
            setkwhAdjustment({ value: response.data.data[0].metadata.kwhAdjustment })
            if (response.data.data[0].granularity === 'annual') {
              setAnnualKwhValue(response.data.data[0].user_data[0].quantityValue)
            }
          }
        })
      }
    });
  };

  const handleChange = (name, value) => {


    if (name === 'utility') {
      let data = [];
      let lseId;
      // eslint-disable-next-line array-callback-return
      (utilityDropdownData).find((item) => {
        if (item.value === value) {
          lseId = item.lse_id
        }
      });
      setUtilityDropdownValue(value);
      setTariffId(lseId);
      getTraiffs(projectId.id, lseId).then((response) => {
        if (response.data.success) {
          (response.data.data)?.map((item) =>
            data.push({
              tariff_id: item.tariff_id,
              name: item.tariff_name,
              utility_rate_id: item.id,
              value: item.tariff_name
            }));
          setTariffDropdownData(data);
          setTariffDropdownValue(data[0]?.name);
          setTariffs(projectId.id, data[0]?.utility_rate_id );
        }
      });
    }
    if (name === 'tariff') {
      setTariffDropdownValue(value);
      let utilityRateId;
      // eslint-disable-next-line array-callback-return
      tariffDropdownData.find((item) => {
        if (item.value === value) {
          utilityRateId = item.utility_rate_id;
        }
      });
      setUtilityRateId(utilityRateId);
      setTariffs(projectId.id, utilityRateId );
    }
    if (name === 'zone') {
      setZoneDropdownValue(value);
      let territoryId;
      // eslint-disable-next-line array-callback-return
      zoneDropdownData.find((item) => {
        if (item.value === value) {
          territoryId = item.territory_id;
        }
      })
      setTerritory(projectId.id, { territory_id: territoryId }).then((response) => {
        if (response.data.success) {
          setRateSchedules(JSON.parse(response.data.data.metadata).rates);
          setFee({ monthlyCharge: response.data.data.min_monthly_charges, monthlyFee: response.data.data.monthly_fee })
        }
      })
    }

    if (name === 'interval') {
      setPanelValue(value);
      setValue(0);
    }

    if (name === "interval") {
      setPanelValue(value);
      setValue(0);
    }
  };

  const refreshTariff = () => {
    setTariff(projectId.id, { utility_rate_id: utilityRateId }).then((response) => {
      if (response.data.success) {
        let acntProp = {};
        let tariffData = {};
        (JSON.parse(response.data.data.properties)).forEach((item) => {
          if (acntProp) {
            acntProp = { ...acntProp, [item.keyName]: false }
          }
        });
        setRateSchedules(JSON.parse(response.data.data.rates));
        setFee({ monthlyCharge: response.data.data.min_monthly_charges, monthlyFee: response.data.data.monthly_fee })
        setAccountProperties(acntProp);
        tariffData = JSON.parse(response.data.data.tariff_data);
        setRateScheduleToggle({
          hasTieredRates: tariffData.hasTieredRates,
          hasTimeOfUseRates: tariffData.hasTimeOfUseRates
        })
        if (tariffData.hasTieredRates) {
          getZones(tariffId);
        }
      }
    })
  }

  const validate = (e) => {
    const re = /^-?([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (e.target.name === 'annualkWh') {
      if (re.test(e.target.value)) {
        setAnualKwh({ value: e.target.value, validataion: false });
        setAnnualKwhValue(e.target.value)
      } else {
        setAnualKwh({ validataion: true });
        setAnnualKwhValue(e.target.value)
      }
    }

    if (e.target.name === 'kwhAdjustment') {
      if (re.test(e.target.value)) {
        setkwhAdjustment({ value: e.target.value, validataion: false });
      } else {
        setkwhAdjustment({ validataion: true });
      }
    }
  };


  return (
    <div className='consumptionMain'>
      <UtilityPanelbox projectId={projectId.id} />
      <hr />
      <div className="secondMain">
        <div className="utilitySts">
          <div className="utilityAPItxt">
            <span className="panelText">UtilityAPI Status</span>
            <Button
              styleClass="button-var2_utilityAPIBrowser"
              variant="contained"
              buttonColor="success"
              label="Import"
            />
          </div>
          <span className="alertText">Not allowed by channel partner</span>
        </div>
        <div className="greenBtn">
          <div className="greenBtntxt">
            <span className="greenBtnText">GreenButton Data</span>
            <Button
              styleClass="button-var2_greenBtnData"
              variant="contained"
              buttonColor="success"
              label="Choose"
            />
          </div>
        </div>
      </div>
      <div className="refreshdropdown">
        <div className="refreshdropdown2">
          <FormControl>
            <div className="utilitySelect">
              <Stack spacing={2}>
                <Autocomplete
                  id="size-small-filled"
                  size="small"
                  disableClearable
                  value={utilityDropdownValue || null}
                  defaultValue={null}
                  options={utilityDropdownData}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                  isOptionEqualToValue={(option, value) => { return option.value === value }}
                  popupIcon={<KeyboardArrowDownIcon sx={{ color: '#5029DD' }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Utility"
                      placeholder=""
                    />
                  )}
                  onChange={(e, value) => handleChange('utility', value?.value)}
                />
              </Stack>
            </div>
          </FormControl>

          <FormControl>
            <div className="utilitySelect">
              <Stack spacing={2}>
                <Autocomplete
                  id="size-small-filled"
                  size="small"
                  disableClearable
                  options={tariffDropdownData}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={tariffDropdownValue || null}
                  defaultValue={null}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  popupIcon={
                    <KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Tariff"
                      placeholder=""
                    />
                  )}
                  onChange={(e, value) => handleChange('tariff', value?.value)}
                />
              </Stack>
            </div>
          </FormControl>
          <FormControl>
            <div className="utilitySelect">
              <Stack spacing={2}>
                <Autocomplete
                  id="size-small-filled"
                  size="small"
                  disableClearable
                  options={zoneDropdownData}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={zoneDropdownValue || null}
                  defaultValue={null}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  popupIcon={
                    <KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />
                  }
                  disabled={!rateScheduleToggle?.hasTieredRates}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Zone"
                      placeholder=""
                    />
                  )}
                  onChange={(e, value) => handleChange('zone', value?.value)}
                />
              </Stack>
            </div>
          </FormControl>
        </div>
        <Button styleClass="button-var2_refreshBtn" variant="contained" buttonColor="success" label="Refresh" disabled={(utilityDropdownValue === null) && (tariffDropdownValue === null)} handleClick={refreshTariff} />
      </div>
      {!isEmpty(accountProperties) ? (
        <>
          <p className="account_pro">Account Properties</p>
          {Object.keys(accountProperties).map((key, index) => {
            return (
              <div className="checkbox">
                <Checkbox checked={accountProperties[key]} onChange={(e) => SwitchhandleChange(key)} />
                <p className='check_p'>{key}</p>
              </div>
            )
          })}

          <Button styleClass="button-var2_save" variant="contained" buttonColor="success" label="Save" handleClick={saveAccountProperties} />
          <hr /></>)
        : ''}


      {(!isEmpty(rateSchedules) && (rateSchedules).length > 0) ? (
        <>
          <div className='rateSchedulesMain'>
            <p className='rateSchedulesTitle'>Rate Schedules</p>
            <div>
              <RateSchedules tableData={rateSchedules} hasTieredRates={rateScheduleToggle.hasTieredRates} hasTimeOfUseRates={rateScheduleToggle.hasTimeOfUseRates} />
            </div>
          </div>
          <div className='feeNmonthlyCharge'>
            <div className='text_field' style={{ background: '#F1F1F1' }}>
              <div className="box_content">
                <p id="value">Fee</p>
                <p id="dollar">{fee.monthlyFee}</p>
              </div>
            </div>
            <div className='text_field' style={{ background: '#F1F1F1' }}>
              <div className="box_content">
                <p id="value">Minimum Monthly Charge</p>
                <p id="dollar">{fee.monthlyCharge}</p>
              </div>
            </div>
          </div>
        </>
      ) : []}



      <div className='calculationFields'>
        <FormControl >
          <div className='intervalDropdown'>
            <Stack spacing={2}>
              <Autocomplete
                id="size-small-filled"
                size="small"
                name="interval"
                disableClearable
                options={intervalOptions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option['name']}
                isOptionEqualToValue={(option, value) => { return option.value === value }}
                value={panelValue || null}
                defaultValue={null}
                popupIcon={<KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Interval"
                    placeholder=""
                  />
                )}
                onChange={(e, value) => handleChange("interval", value?.value)}
              />
            </Stack>
          </div>
        </FormControl>
        <div>
          <TextField
            className='anualField' name='annualkWh' style={anualKwh.validataion ? { border: '1px solid red' } : {}}
            value={panelValue === 'Monthly' ? '' : annualkWhValue} label="Annual kWh Usage"
            variant="filled" onChange={validate} disabled={panelValue === '' || panelValue === 'Monthly'}
            InputLabelProps={{
              shrink: true
            }}
          />
          {anualKwh.validataion ? <p style={{ color: 'red', fontFamily: 'ActualR', fontWeight: 400, fontSize: '10px' }}>Invalid Input</p> : ''}
        </div>
        <Button styleClass="button-var2_calculate" variant="contained" buttonColor="success" label="Calculate" handleClick={submitAnualKwhAdjustment} disabled={panelValue !== 'Annual' || anualKwh.value === null || anualKwh.validataion} />
        <div>
          <TextField className='anualField' name='kwhAdjustment'
            style={kwhAdjustment.validataion ? { border: '1px solid red' } : {}}
            label="kWh Adjustment" variant="filled" onChange={validate}
            value={kwhAdjustment.value}
            InputLabelProps={{
              shrink: true
            }}
            disabled={isEmpty(consumptionData)}
          />
          {kwhAdjustment.validataion ? <p style={{ color: 'red', fontFamily: 'ActualR', fontWeight: 400, fontSize: '10px' }}>Invalid Input</p> : ''}
        </div>
        <Button styleClass="button-var2_update" variant="contained" buttonColor="success" label="Update" handleClick={submitKwAdjustment} disabled={isEmpty(consumptionData) || kwhAdjustment.value === null || kwhAdjustment.validataion === true} />
        <Button styleClass="button-var2_calculator" variant="contained" buttonColor="success" label="Save" handleClick={submitUserData} disabled={panelValue !== 'Monthly'} />
      </div>
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {(panelValue) && (panelValue !== 'Monthly') ? (
              <Tabs
                value={value}
                onChange={handleTabs}
                aria-label="basic tabs example"
              >
                <Tab className="tab" label="Genability" />
              </Tabs>
            ) : (
              <Tabs
                value={value}
                onChange={handleTabs}
                aria-label="basic tabs example"
              >
                <Tab className="tab" label="User" />
                <Tab className="tab" label="Genability" />
              </Tabs>)}
          </Box>
          <div className="tab_panel">
            {(panelValue) && (panelValue !== 'Monthly') ? (
              <TabPanel value={value} index={0}>
                <GenabilityTable genabilityTableData={(panelValue !== '' && panelValue.toLowerCase() === consumptionData?.granularity) ? consumptionData?.genability_data : null} />
              </TabPanel>
            ) : (
              <>
                <TabPanel value={value} index={0}>
                  <RateCalculationTable submitUserData={submitUserData} userTableData={((panelValue !== '') && (panelValue.toLowerCase() === consumptionData?.granularity)) ? consumptionData?.user_data : null} submitUserDataFlag={submitUserDataFlag} setSubmitUserDataFlag={setSubmitUserDataFlag} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <GenabilityTable genabilityTableData={(panelValue !== '' && panelValue.toLowerCase() === consumptionData?.granularity) ? consumptionData?.genability_data : null} />
                </TabPanel>
              </>
            )}

          </div>
        </Box>
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Consumption;