import React, { useEffect, useState, useContext } from "react";
import Grid from "../../../../../../layout/UI/dataGrid";
import { Input } from "@mui/material";
import { DashboardContext } from "../../../../../../services/dashboard/dashboardContext";

function RateCalculationTable(props) {
  const context = useContext(DashboardContext);

  const {
    submitUserData,
    setSubmitUserDataFlag,
    submitUserDataFlag,
    userTableData,
  } = props;

  const [rowData, setRowData] = React.useState([]);
  const [tableData, setTableData] = useState([]);

  const [dateRange, setdateRange] = useState({
    from: null,
    to: null,
  });

  const removeRow = (id) => {
    const updatedData = rowData.filter((item) => item.id !== id);
    setRowData(updatedData);
  };

  useEffect(() => {
    if (userTableData?.length > 0) {
      const data = [];
      userTableData.forEach((element, index) => {
        let channel = { ...element, id: index };
        data.push(channel);
      });
      setRowData(data);
    } else {
      setRowData([
        ...rowData,
        {
          id: Math.random(),
          fromDateTime: "",
          toDateTime: "",
          quantityValue: null,
          quantityUnit: "kwh",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTableData]);

  const tableDataLength = context.state.consumptionUserDataLength;

  const changeDate = (rowIndx) => {
    if (rowIndx >= 0) {
      const rowDate = rowData[rowIndx].fromDateTime?.substring(0, 10);
      return rowDate;
    }
  };

  const changeHandler = (id, name) => (e) => {
    const rowIndex = rowData.findIndex((row) => row.id === id);
    const newtableData = [...rowData];
    if (name === "fromDateTime") {
      newtableData[rowIndex]["fromDateTime"] = e.target.value;
      setdateRange({ from: e.target.value });
    }
    if (name === "toDateTime") {
      newtableData[rowIndex]["toDateTime"] = e.target.value;
      setdateRange({ to: e.target.value });
    }
    if (name === "quantityValue") {
      newtableData[rowIndex]["quantityValue"] = parseInt(e.target.value);
    }

    setTableData(newtableData);
  };

  useEffect(() => {
    if (submitUserDataFlag) {
      const newArr = tableData?.map(({ id, ...rest }) => rest);
      submitUserData(newArr);
      setSubmitUserDataFlag(false);
    }
  }, [
    submitUserDataFlag,
    tableData,
    submitUserData,
    setSubmitUserDataFlag,
    context.setSaveDisableBtn,
  ]);

  const addRow = () => {
    let fromDate = new Date(rowData[rowData.length -1].toDateTime);
    const inputDay = fromDate.getDate();

    // Add 1 day to the from date
    const newFromDate = new Date(fromDate.setDate(inputDay + 1));
    const startDate = newFromDate.toISOString().substring(0, 10);
    setRowData([
      ...rowData,
      {
        id: Math.random(),
        fromDateTime: startDate,
        toDateTime: "",
        quantityValue: null,
        quantityUnit: "kwh",
      },
    ]);
  };

  const columns = React.useMemo(
    () => [
      {
        field: "fromDateTime",
        type: "string",
        width: 350,
        headerName: "Start Date",
        headerAlign: "left",
        sortable: false,
        cellClassName: "left-align",
        renderCell: (params) =>
          tableDataLength !== null &&
          params.api.getRowIndex(params.row.id) < tableDataLength ? (
            <p>{params.row.fromDateTime.substring(0, 10)}</p>
          ) : (
            <div variant="outlined">
              <input
                className="tableField"
                type="date"
                id="fromDateTime"
                value={params.row.fromDateTime}
                onChange={changeHandler(params.row.id, "fromDateTime")}
              />
            </div>
          ),
      },
      {
        field: "toDateTime",
        type: "string",
        width: 350,
        headerName: "End Date",
        cellClassName: "left-align",
        headerAlign: "left",
        sortable: false,
        renderCell: (params) =>
          tableDataLength !== null &&
          params.api.getRowIndex(params.row.id) < tableDataLength ? (
            <p>{params.row.toDateTime.substring(0, 10)}</p>
          ) : (
            <div variant="outlined">
              <input
                className="tableField"
                type="date"
                id="toDateTime"
                min={
                  changeDate(params.api.getRowIndex(params.row.id)) || ""
                }
                value={params.row.toDateTime || ""}
                onChange={changeHandler(params.row.id, "toDateTime")}
              />
            </div>
          ),
      },
      {
        field: "quantityValue",
        type: "number",
        width: 300,
        headerName: "Term kW",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) =>
          // <TextField variant="outlined" value={value} />,
          tableDataLength !== null &&
          params.api.getRowIndex(params.row.id) < tableDataLength ? (
            <p>{params.row.quantityValue}</p>
          ) : (
            <Input
              className="no-arrows"
              onChange={changeHandler(params.row.id, "quantityValue")}
              type="number"
            />
          ),
      },
      {
        field: "",
        type: "",
        width: 50,
        headerAlign: "left",
        sortable: false,
        cellClassName: "right-align",

        renderCell: (params) => {
          if (
            params.api.getRowIndex(params.row.id) === rowData.length - 1 &&
            rowData.length < 13
          ) {
            return (
              <div
                onClick={addRow}
                title="Click to add row"
                style={{
                  width: "50px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <i className="icon-plus1 electrum-font"></i>
              </div>
            );
          }
          if (params.api.getRowIndex(params.row.id) >= tableDataLength) {
            return (
              <div
                onClick={() => removeRow(params.row.id)}
                title="Click to remove row"
                style={{
                  width: "50px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <i className="icon-close electrum-font"></i>
              </div>
            );
          }
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[addRow, changeHandler, dateRange.from, dateRange.to]
  );

  return (
    <div className="RateCalculationTable">
      <Grid
        columns={columns}
        rows={rowData}
        autoHeight
        pagination={false}
        hideFooter
        disableColumnSort={true}
      />
    </div>
  );
}

export default RateCalculationTable;
