import React,{ Component } from 'react';

const ProductContext = React.createContext();

class ProductProvider extends Component {
    state = {
        selectedProductCategory : "",
        activeBreadcrumb: "",
    }
    setSelectedProductCategory = (category) => {
        this.setState({selectedProductCategory: category})
    }
    setActiveBreadcrumb = (data) => {
        this.setState({activeBreadcrumb : data})
    }
    render(){
        return(
            <ProductContext.Provider value={{
                state: this.state,
                setSelectedProductCategory: this.setSelectedProductCategory,
                setActiveBreadcrumb: this.setActiveBreadcrumb
            }}>
                {this.props.children}
            </ProductContext.Provider>
        )
    }
}


export {ProductContext, ProductProvider}