import React, { useEffect, useState } from "react";
import Grid from "../../../../../../layout/UI/dataGrid";

function RateSchedules(props) {

  const { tableData, hasTimeOfUseRates, hasTieredRates } = props;
  const [Data, setData] = useState()

  useEffect(() => {
    if (tableData.length > 0) {
      const data = [];
      tableData.forEach((element, index) => {
        let channel = { ...element, id: index };
        data.push(channel);
      });
      setData(data);
    }
  }, [tableData])



  const columns = hasTieredRates ?
    [
      {
        field: "start_date",
        type: "string",
        width: 250,
        headerName: "Start Date",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => <p>{params.row.start_date}</p>,

      },
      {
        field: "end_date",
        type: "string",
        width: 250,
        headerName: "End Date",
        cellClassName: "left-align",
        headerAlign: "left",
        sortable: false,
        renderCell: (params) =>
          <p className="date">{params.row.end_date}</p>,

      },
      {
        field: "max_kwh",
        type: "string",
        width: 200,
        headerName: 'Max kWh',
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) =>
          <p className="date">{params.row.max_kwh}</p>,

      },
      {
        field: "rate_per_kwh",
        type: "number",
        width: 150,
        headerName: "Rate($)",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => <p className="merge-text">{params.row.rate_per_kwh}</p>,

      },

      {
        field: "season_type",
        type: "string",
        width: 150,
        headerName: "Season Type",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => <p className="merge-text">{params.row.season_type}</p>,

      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
    ]
    : hasTimeOfUseRates ?
      [
        {
          field: "start_date",
          type: "string",
          width: 150,
          headerName: "Start Date",
          headerAlign: "left",
          cellClassName: "left-align",
          sortable: false,
          renderCell: (params) => <p>{params.row.start_date}</p>,
        },
        {
          field: "end_date",
          type: "string",
          width: 190,
          headerName: "End Date",
          cellClassName: "left-align",
          headerAlign: "left",
          sortable: false,
          renderCell: (params) =>
            <p className="date">{params.row.end_date}</p>,

        },
        {
          field: "time_range",
          type: "string",
          width: 150,
          headerName: 'Time Range',
          headerAlign: "left",
          cellClassName: "left-align",
          sortable: false,
          renderCell: (params) =>
            <p className="date">{params.row.time_range}</p>,

        },
        {
          field: "rate_per_kwh",
          type: "number",
          width: 150,
          headerName: "Rate($)",
          headerAlign: "left",
          cellClassName: "left-align",
          sortable: false,
          renderCell: (params) => <p className="merge-text">{params.row.rate_per_kwh}</p>,

        },

        {
          field: "peak_type",
          type: "number",
          width: 150,
          headerName: "Peak Type",
          headerAlign: "left",
          cellClassName: "left-align",
          sortable: false,
          renderCell: (params) => <p className="merge-text">{params.row.peak_type}</p>,

        },

        {
          field: "season_type",
          type: "string",
          width: 150,
          headerName: "Season Type",
          headerAlign: "left",
          cellClassName: "left-align",
          sortable: false,
          renderCell: (params) => <p className="merge-text">{params.row.season_type}</p>,

        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
      ]
      : [];


  return (
    <div className="rateSchedules">
      {(Data?.length > 0) ? (
        <Grid
          columns={columns}
          rows={Data}
          autoHeight
          pagination={false}
          hideFooter
        />
      ) : ''}

    </div>
  )
}

export default RateSchedules;
