import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as MUI from "../../../../../../utils/components/mui";
import FormControl from "@mui/material/FormControl";
import { Button, showToast } from "../../../../../../layout";
import { Autocomplete, Stack, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEmpty } from "lodash";

import downloadicon from "../../../../../../assets/images/Vector.png";

import {
  uploadFileMulti,
  getProjectFilesList,
} from "../../../../../../services";

const UtilityPanelbox = (props) => {
  const history = useHistory();

  const { projectId } = props;

  const [utilityImgData, setUtilityImgData] = useState([]);
  const [panelBoxValue, setPanelBoxValue] = useState("");
  const [panelType, setpanelType] = useState("");

  const utilityBillData = utilityImgData.filter(
    (item) =>
      item?.metadata?.type === "utility" &&
      (item?.metadata?.url.includes("jpg") || 
      item?.metadata?.url.includes("jpeg") ||
        item?.metadata?.url.includes("png") ||
        item?.metadata?.url.includes("pdf"))
  );
  const panelBoxData = utilityImgData.filter(
    (item) =>
      (item?.metadata?.type === "panelboxPhoto" || item?.metadata?.type === "electricityBill") &&
      (item?.metadata?.url.includes("jpg") ||
      item?.metadata?.url.includes("jpeg") ||
        item?.metadata?.url.includes("png") ||
        item?.metadata?.url.includes("pdf"))
  );

  useEffect(() => {
    getProjectFilesList({
      projectId: projectId,
      search: {
        type: ["utitlity_bill", "panel_box_photo"],
      },
    }).then((files) => {
      if (files.data.success) {
        setUtilityImgData(files.data.data.project_files);
      }
    });
  }, [projectId]);

  useEffect(() => {
    setpanelType(panelBoxData[0]?.metadata.panel_type);
  }, [panelBoxData]);

  //This {handleClicked} function is to handle the image upload functionality for UTILITY and PANEL BOX
  const handleClicked = (event, key) => {
    let FileData = (event.target || event.srcElement).files;
    const imageData = new FormData();
    let totalFiles = 0;
    for (let i = 0; i < FileData.length; i++) {
      imageData.append(`myfile[${totalFiles}]`, FileData[i]);
      totalFiles = totalFiles + 1;
    }
    imageData.append("project_id", projectId);
    imageData.append("type", key);
    if (key === "panelboxPhoto" && !isEmpty(panelBoxValue)) {
      imageData.append("panel_type", panelBoxValue);
    }
    imageData.append("isApproved", 1);
    uploadFileMulti(imageData).then((res) => {
      if (res.data.success) {
        getProjectFilesList({
          projectId: projectId,
          search: {
            type: ["utitlity_bill", "panel_box_photo"],
          },
        }).then((files) => {
          if (files.data.success) {
            setUtilityImgData(files.data.data.project_files);
            showToast(
              "SUCCESS",
              `${totalFiles} ${
                totalFiles > 1 ? "files" : "file"
              } uploaded successfully.`
            );
          }
        });
      } else {
        showToast("ERROR", "Something went wrong!");
      }
    });
  };

  const panelValues = [
    {
      name: "50 Amp",
      value: "50 Amp",
    },
    {
      name: "100 Amp",
      value: "100 Amp",
    },
    {
      name: "125 Amp",
      value: "125 Amp",
    },
    {
      name: "150 Amp",
      value: "150 Amp",
    },
    {
      name: "200 Amp",
      value: "200 Amp",
    },
    {
      name: "400 amp",
      value: "400 amp",
    },
  ];

  const handleChange = (name, value) => {
    if (name === "panel") {
      setPanelBoxValue(value);
    }
  };

  return (
    <div className="main">
      <div className="utilityBill">
        <div className="utilityMain">
          <span className="utilityText">Utility Bill</span>
          <div>
            <input
              type="file"
              accept="image/*,application/pdf"
              style={{ display: "none" }}
              id="image_link"
              onChange={(data) => handleClicked(data, "utility")}
              multiple
            />
            <MUI.Tooltip title="Files supported: PNG, JPG, JPEG, PDF ">
              <label htmlFor="image_link" style={{ marginLeft: "0px" }}>
                <Button
                  variant="success"
                  buttonColor="primary"
                  component="span"
                  label="Browse"
                  styleClass="button-var2_utilityBrowser"
                  className="upload_button"
                ></Button>
              </label>
            </MUI.Tooltip>
          </div>
        </div>
        <div className="imageUtilityDiv">
          {utilityBillData.map((item, index) => {
            return (
              <div className="imageContainer">
                {item?.metadata?.url.includes("pdf") ? (
                  <>
                    <img
                      key={index}
                      className="utilityImageStyles"
                      src={`https://icon-library.com/images/pdf-icon-svg/pdf-icon-svg-10.jpg`}
                      alt={item?.metadata?.original_name}
                    />

                    <a
                      target="_blank"
                      href={item?.metadata?.url}
                      download={item?.metadata?.original_name}
                      rel="noreferrer"
                    >
                      <img
                        key={index}
                        src={downloadicon}
                        className="downloadIcon"
                        title="Click to download"
                        alt={item?.metadata?.original_name}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <img
                      key={index}
                      className="utilityImageStyles"
                      src={item?.metadata?.url}
                      alt={item?.metadata?.original_name}
                    />
                    <a
                      href={item?.metadata?.url}
                      download={item?.metadata?.original_name}
                    >
                      <img
                        key={index}
                        src={downloadicon}
                        className="downloadIcon"
                        title="Click to download"
                        alt={item?.metadata?.original_name}
                      />
                    </a>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <p
          className="viewMore"
          onClick={() => {
            history.push(`/dashboard/projects/project-list/files/${projectId}`);
          }}
        >
          View more
        </p>
      </div>
      <div className="panelMain">
        <div className="panelBox">
          <div className="panelDiv">
            <span className="panelText">Panel Box</span>
            <div>
              <input
                type="file"
                accept="image/*,application/pdf"
                style={{ display: "none" }}
                id="image_link2"
                onChange={(data) => handleClicked(data, "panelboxPhoto")}
                multiple
              />
              <MUI.Tooltip title={"Files supported: PNG, JPG, JPEG, PDF"}>
                <label htmlFor="image_link2" style={{ marginLeft: "0px" }}>
                  <Button
                    variant="success"
                    buttonColor="primary"
                    component="span"
                    label="Browse"
                    styleClass="button-var2_panelBrowser"
                    className="upload_button"
                  ></Button>
                </label>
              </MUI.Tooltip>
            </div>
          </div>
          <hr className="verticalDivider" />
          <FormControl>
            <div className="selectPanel">
              <Stack spacing={2}>
                <Autocomplete
                  id="size-small-filled"
                  size="small"
                  disableClearable
                  options={panelValues}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option["name"]
                  }
                  value={panelBoxValue ? panelBoxValue : panelType}
                  popupIcon={
                    <KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Panel"
                      placeholder=""
                    />
                  )}
                  onChange={(e, value) => handleChange("panel", value?.value)}
                />
              </Stack>
            </div>
          </FormControl>
        </div>
        <div className="imagePanelDiv">
          {panelBoxData.map((item, index) => {
            return (
              <div className="imageContainer">
                {item?.metadata?.url.includes("pdf") ? (
                  <>
                    <img
                      key={index}
                      className="PanelImageStyles"
                      src={`https://icon-library.com/images/pdf-icon-svg/pdf-icon-svg-10.jpg`}
                      alt={item?.metadata?.original_name}
                    />

                    <a
                      target="_blank"
                      href={item?.metadata?.url}
                      download={item?.metadata?.original_name}
                      rel="noreferrer"
                    >
                      <img
                        key={index}
                        src={downloadicon}
                        title="Click to download"
                        className="downloadIcon"
                        alt={item?.metadata?.original_name}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <img
                      key={index}
                      className="PanelImageStyles"
                      src={item?.metadata?.url}
                      alt={item?.metadata?.original_name}
                    />
                    <a
                      href={item?.metadata?.url}
                      download={item?.metadata?.original_name}
                    >
                      <img
                        key={index}
                        src={downloadicon}
                        className="downloadIcon"
                        title="Click to download"
                        alt={item?.metadata?.original_name}
                      />
                    </a>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <p
          className="viewMore"
          onClick={() => {
            history.push(`/dashboard/projects/project-list/files/${projectId}`);
          }}
        >
          View more
        </p>
      </div>
    </div>
  );
};

export default UtilityPanelbox;
