import axiosInstance from '../../utils/interceptors/AxiosInstance';
import { ENGINE_URL } from '../CONSTANTS';
// import {showToast} from '../../utils/components/toast';
import { getPermissions, getModulePermisions } from '../../services';
import {setPermissions , setModulePermissions} from '../../config';

export const doLogin = async(userData, legacy = false) =>{
    const params = {
        avatar: legacy ? userData.avatar : userData.profileData.imageUrl,
        token: legacy ? userData.token : userData.token.id_token
    }
    return axiosInstance.post(ENGINE_URL + `socialLogin/storeSystemUsers`, params);

}

export const userAuth = async(userData, legacy = false) => {
    
    const params = {
        avatar: legacy ? userData.avatar : userData.profileData.imageUrl,
        token: legacy ? userData.token : userData.token.id_token
    }

    return new Promise((resolve, reject) => {
        try {
            axiosInstance.post(ENGINE_URL + `socialLogin/storeSystemUsers`, params)
            .then((res) => {
                // console.log(res);
                resolve({data : res.data.data , isLoggedIn: true})
                if(res.data){
                    localStorage.setItem('userData', JSON.stringify(res.data.data));
                    localStorage.setItem('token', JSON.stringify(res.data.data.token));
                // showToast('SUCCESS' ,`Logged in successfully welcome ${res.data}`)
                }
                getModulePermisions().then((response) => {
                      if(response.data.success){
                        setModulePermissions(response.data.data);
                      }else{
                        setModulePermissions([]);
                      }
                     })
                getPermissions().then((response) => {
                    if(response.data.success){
                      // console.log(response.data.data);
                      setPermissions(response.data.data.permissions_list);
                    }else{
                      setPermissions([]);
                    }
                })
            }).catch ((err) => {
                // showToast('ERROR' ,`Failed to login1.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}

export const userLogout = async() => {
    return axiosInstance.post(ENGINE_URL + `control-center/logout`,{})
}

export const refreshToken = async() => {
    return axiosInstance.post(ENGINE_URL + `control-center/refresh-token`,{})
}
