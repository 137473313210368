import {ROLES, PERMISSIONS, MODULE} from '../../config/roles';
import { SSO_ORIGIN } from '../../services/CONSTANTS';
var timer;

const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => o1[p].checked === o2[p].checked);

const capitalize = (str) =>{
    if(str === '' || str === null|| str === undefined) return ;
   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
//    else return ;
} 
const changeScreen = (history, params = '/') => {
}  
const prepareRoleList = (userRoles) => {
    userRoles.map((role) => { 
        return ROLES[role];
    })
} 

const isActionAllowed = (actionUrl = "") => {
    if(actionUrl === '') return true;
    return (PERMISSIONS.indexOf(actionUrl) >= 0) ? true : false;
};

const isActionAllowedAuth = (actionUrl = []) => {
  if(actionUrl.length === 0) return true;
  const count = actionUrl.filter(url => {
    return PERMISSIONS.indexOf(url) >= 0 ? true : false
  }
  )
  return count.length > 0
}

const isModuleAllowed = (actionUrl = "") => {
  if(actionUrl[0] === '') return true;
  return (MODULE.indexOf(actionUrl[0]) >= 0) ? true : false;
};

const phoneMask = (number) => {
    if(number === '' || number === null || number === undefined) return;
    // return "(XXX) XXXX XXX"
    let newVal = number.toString().replace(/\D/g, '');
    // let newVal = number.toString();
    if (newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
}

const  debounce = (fn, delay) =>  {
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
export const isIFrame = (input) =>
    input !== null && input.tagName === 'IFRAME';

export const establishCommunicationToSSO = (message) => {
  let frame = document.getElementById('ssoFrame');
  // console.log('here')
    if ((frame !== null && frame.tagName === 'IFRAME') && frame.contentWindow) {
      frame.contentWindow.postMessage(message,SSO_ORIGIN);
    }
} 

export {objectsEqual, capitalize, changeScreen, prepareRoleList,isActionAllowed,phoneMask, debounce, isModuleAllowed, isActionAllowedAuth};        