import React, {useState,useEffect,lazy,Suspense, useContext} from 'react'
import { useParams } from "react-router-dom";

import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import { getprojectDetails } from "../../../../../services";
import Consumption from './consumption';
import SolarDesign from './solarDesign';
import Solutions from './solutions';
import { DashboardContext } from '../../../../../services/dashboard/dashboardContext';
const AccountDetail = lazy(() => import("./financierAccountDetails"));



function Financier() {
  const context = useContext(DashboardContext);
 const [value, setValue] = useState(0);
 const { id } = useParams();
 const [data, setdata] = useState({});
 const markAsComplete = context.state.markAsComplete;
 useEffect(() => {
   getlist();
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 // This useEffect is used to auto route to solutions page when the user clicks on Mark as complete in System Design page
 useEffect(()=>{
  if(markAsComplete===2){
    context.setMarkAsComplete(0)
    setValue(2)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 },[context.state.markAsComplete]);


 const getlist = () => {
  getprojectDetails(id).then((response) => {
     if (response.data.success) {
       setdata(response.data.data);
     }
   });
 };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
     <div className='headerImpersonate'>
      <Suspense fallback={<></>}>
          <AccountDetail projectData={data}></AccountDetail>
        </Suspense>
        </div>
    <div className="financier_component">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab className="tab" label="Consumption Profile" />
                <Tab className="tab" label="System Design" />
                <Tab className="tab" label="Solutions" />
                <Tab className="tab" label="Contracts" />

              </Tabs>
            </Box>
            <div className="tab_panel">
              <TabPanel value={value} index={0}>
                <Consumption></Consumption>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SolarDesign></SolarDesign>
              </TabPanel>
              <TabPanel value={value} index={2}>
              <Solutions></Solutions>
              </TabPanel>
            </div>
          </Box>
        </div>
    </>
  )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default Financier