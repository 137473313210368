import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {LEAD_URL, CONTROL_LEAD_URL, ENGINE_URL } from '../../CONSTANTS';

// getInstallerList?channel_partner_key=&page=1&{}
export const getInstallerListData = async(data = {}) => {
    // return axiosInstance.get(LEAD_URL + `getInstallerList?channel_partner_key=${partner?.api_key ? partner?.api_key : '' }&page=1&{}`)
    return axiosInstance.post(CONTROL_LEAD_URL + `getInstallersList`, data);
   
}

// installerOnBord
export const installerOnBoard = async(data = {}) => {
    return axiosInstance.post(LEAD_URL + `installerOnbord`, data);
}

export const getServiceAreaByInstaller = async(data) => {
    return axiosInstance.post(LEAD_URL + `getServiceAreaByInstaller`, data);
}

export const getStateCountyData = async() => {
    return axiosInstance.get(CONTROL_LEAD_URL + `getStateAndCounties`)
}

export const getInstallerOrgList = async(data = {}) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/control-center/get-installer-org-list`, data);
}

export const installerApprovalHandler = async(data) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/approve-installer-profile`, data)
}

export const sentVerificationEmailHandler = async(data) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/control-center/resend-verification-mail-control-center`, data)
}

export const impersonationHandler = async(data) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/control-center/impersonate-login`, data)
}

export const getInstallerCount = async() => {
    return axiosInstance.get(ENGINE_URL + `installer-dashboard/control-center/get-installer-org-status-count`)
}
export const getAllInstallerForLogs = async(data) => {
    return axiosInstance.get(ENGINE_URL + `control-center/get-installers`,data)
}
export const getInstallerHistoryLogs = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/get-installer-pricing-logs`,data)
}
export const getHistoryFilters = async() => {
    
    return axiosInstance.get(ENGINE_URL + `control-center/get-filters`)
}
export const reviveinstaller = async(data) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/revive-installer-profile`, data)
}
export const installerCompanyFileList = async(data) => {
    return axiosInstance.get(ENGINE_URL + `control-center/get-installer-files/`+data)
}
export const installermodule = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/intaller-profile-files-list-filters`)
}
 export const deleteInstallerAdditionalUsers = async(data) => {
    return axiosInstance.post(ENGINE_URL + `installer-dashboard/delete-installer-user`, data)
 }
//  get list of channel partners mapped to installer
 export const getInstallerChannelPartnerConfig = async(data) => {
    return axiosInstance.post(LEAD_URL + `get-installer-channelpartners-config`, data)
 }
//  get list of channel partner
 export const getChannelPartnerList = async() => {
    return axiosInstance.get(LEAD_URL + `getChannelPartner?{}`)
 }

 // Get list of all the installers -> getInstallerList -> without params
 export const getInstallerDataList = async(data= {}) => {
    return axiosInstance.post(CONTROL_LEAD_URL + `getInstallersList`, data)
 }

 // Get data of individual installer -> Product Tab -> getInstallerlist -> with Params
 export const getInstallerDataByID = async(data= {}) => {
    return axiosInstance.post(CONTROL_LEAD_URL + `getInstallersList`, data)
 }

 // update channel partner mapping for installer - cpMappingByInstaller
export const getInstallerDatasave = async(data= {}) => {
    return axiosInstance.post(LEAD_URL + `cpMappingByInstaller`, data)
 }
 export const getChannelPartnerDatasave = async(data= {}) => {
    return axiosInstance.post(LEAD_URL + `cpInstallerMapping`, data)
 }

 // copy channel partner mapping fron another installer config
 export const copyInstallerConfig = async(data= {}) => {
    return axiosInstance.post(LEAD_URL + `clone-installer-channelpartner-config`, data)
 }
 export const getInstallerAdditionallist = async(data) => {
    return axiosInstance.post(ENGINE_URL+`installer-dashboard/control-center/get-installer-additional-user-list`, data)
    }

    export const copyChannelPartnerConfig = async(data= {}) => {
        return axiosInstance.post(LEAD_URL + `clone-channelpartner-installer-config`, data)
     }
     export const updateInstallerFlags = async(data= {}) => {
        return axiosInstance.post(LEAD_URL +`updateInstallerFlags`, data)
     }








