import {SAVE_PARTNERS, SELECT_TYPE} from '../CONSTANTS'

const initialState = {
   selectedPartner : [],
   DowntimeData : {},
   scheduleType : 'scheduled'
}

export default function maintenanceReducer(state=initialState, action){
    switch(action.type){
        case SAVE_PARTNERS:
            return { ...state, selectedPartner : {...action.payload}}
        case SELECT_TYPE :
            return { ...state, scheduleType : action.payload}
        default:
            return state
    }
}