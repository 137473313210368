import React from 'react';
const  Footer = () => {
  return (
    <>
    <div id="footer-component">
    <p> Copyright © {new Date().getFullYear()} <a href="https://www.electrum.co" target="_blank" rel="noreferrer">Electrum.co</a>. All rights reserved.</p>
    </div>
    </>
  );
}

export default Footer;
