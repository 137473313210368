/**
 *@param {
  show = false,
  title = 'Title', 
  handleClose, 
  data = {},
  Modelbody,
  buttonText = ['Cancel', 'Save'],
}
 */

import React, {memo} from 'react';
import * as MUI from '../../utils/components/mui'

 const  ScrollDialog = (props) =>{

  const scroll = 'paper';
  // const [scroll, setScroll] = React.useState('paper');     // Scroll type can be paper or body type.

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.show) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.show]);
  return (
    <div className='dialog-box'>
      {
        props.show ?
      <MUI.Dialog
        fullScreen = {false}
        maxWidth = {props.maxWidth? props.maxWidth : 'sm'}
        open={props.show}
        onClose={props.crossMark ? null : props.handleClose}
        scroll={scroll}
        className = {props.className}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <MUI.DialogTitle id="scroll-dialog-title" className="d-flex justify-content-between align-items-center">
          <div>
          <p className="control-bold"  style={{ marginRight:"40px"}}>{props.title}</p>
          {/* { props.subTitle && <p className="dialog-subTitle"  style={{ margin:"auto"}}>{props.subTitle}</p> } */}
          </div>
          {
            !props.crossMark &&
          <p className ="cancel-popup" onClick={props.handleClose}>X</p> 
          }
        </MUI.DialogTitle>
        <MUI.DialogContent dividers={scroll === 'paper'}>
          <MUI.DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            children = {props.children}
            component ='div'
          >
           
          </MUI.DialogContentText>
        </MUI.DialogContent>
        {
          props.ErrorText ?
          <p className="d-block invalid-feedback text-center">{props.ErrorText}</p>
          : null
        }
        {
          props.buttonText.length > 0 &&
        <MUI.DialogActions>
          {
            props.version === 'second' ?
            <>
              {
                props.buttonText.length > 1 && <MUI.Button onClick={props.saveChanges} disabled={props.disabled ? props.disabled : false} variant="contained" className="button-var2">{props.buttonText[1]}</MUI.Button>
              }
              <MUI.Button onClick={props.handleClose} variant="outlined" className="button-var3">{props.buttonText[0]}</MUI.Button>

            </>
            :
            <>
            <MUI.Button onClick={props.handleClose} variant="outlined" className="button-var3">{props.buttonText[0]}</MUI.Button>
              {
                props.buttonText.length > 1 && <MUI.Button onClick={props.saveChanges} disabled={props.disabled ? props.disabled : false} variant="contained" className="button-var2">{props.buttonText[1]}</MUI.Button>
              }
            </>
          }
        </MUI.DialogActions>
       }
      </MUI.Dialog>
      : null
      }
    </div>
  );
}
export default memo(ScrollDialog);
