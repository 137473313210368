import {GET_INSTALLER, GET_SERVICE_AREA_BY_INSTALLER, SET_APPROVAL_SYSTEM_TYPE} from '../CONSTANTS'
export default function installerReducer(state={},action){
    switch(action.type){
        case GET_INSTALLER:
            return { ...state, list : action.payload }
        case GET_SERVICE_AREA_BY_INSTALLER:
            return { ...state, service_area : action.payload }
        case SET_APPROVAL_SYSTEM_TYPE :
            return {...state, approvalSystemType : action.payload}    
        default:
            return state
    }
}