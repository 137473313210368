/**
 * TODO
 * Declare all router constants here
 */

 export const ROOT = "/";
 export const LOGIN = ROOT;
 export const DASHBOARD = {
     INIT : "/dashboard",
     INSTALLER_LIST : "/dashboard/installers/installer-dashboard",
     INSTALLER_EDIT: "/dashboard/installers/list/edit/:id",
     INSTALLER_PRODUCT : "/dashboard/installers/list",
     INSTALLER_APPROVAL_SYSTEM : '/dashboard/installers/dashboard-approval',
     INSTALLER_FILE_LIST : '/dashboard/installers/dashboard-approval/file/:id',
     INSTALLER_HPWH_SERVICE : '/dashboard/installers/hpwh-service',
     INSTALLER_SERVICE : '/dashboard/installers/service',
     INSTALLER_TEMPLATE : '/dashboard/installers/template',
     INSTALLER_LOG_SYSTEM : '/dashboard/installers/history',
     PRODUCTS_MAPPING : '/dashboard/products/product-mapping',
     PRODUCTS_MAKER : '/dashboard/products/maker-mapping',
     PRODUCTS_MODEL : '/dashboard/products/category-model',
     PRODUCTS_EQUIPMENT : '/dashboard/products/equipment/:category',
     PRODUCTS_EQUIPMENT_EDIT : '/dashboard/products/equipment/:category/edit/:id',
     PRODUCTS_EDITOR : '/dashboard/products/editor/:category',
     PRODUCTS_ADD_PRODUCT : '/dashboard/products/editor/add-product/:id',
     PRODUCTS_JSON : '/dashboard/products/product-editor/:id',
     PRODUCTS_MANUFACTURER_LIST : '/dashboard/products/manufacturer',
     PRODUCTS_MANUFACTURER_CREATE : '/dashboard/products/manufacturer/add',
     PRODUCTS_MANUFACTURER_EDIT : '/dashboard/products/manufacturer/edit/:id',
     TOOLS_LD_HISTORY  : '/dashboard/ld-history',
     TOOLS_PROXIMITY : '/dashboard/proximity-zips',
     TOOLS_TRUST_VAULT : '/dashboard/trust-value',
     TOOLS_PRODUCT_CONFIG : '/dashboard/tools/product-config',
     TOOLS_PARTNER_UTILITY : '/dashboard/tools/partner-config',
     TOOLS_CHANNEL_MAPPING : '/dashboard/tools/channel-mapping',
     TOOLS_CHANNEL_EDIT:'/dashboard/tools/channel-mapping/:type/:id',
     PROJECT_LIST : '/dashboard/projects/project-list',
     PROJECT_LIST_NEW : '/dashboard/projects/project-list-new',
     PROJECT_FILES_LIST:'/dashboard/projects/project-list/files/:id',
     TOOLS_ERROR_SUMMARY : '/dashboard/tools/error-summary',
     TOOLS_ERROR_LIST : '/dashboard/tools/error-list',
     TOOLS_FILE_UPLOADER : '/dashboard/tools/file-uploader',
     USERS_LIST : '/dashboard/users/list' ,
     USERS_ROLES : '/dashboard/users/roles',
     USERS_HISTORY_LOGS : '/dashboard/users/history',
     DOWNTIME_CONFIG : '/dashboard/tools/downtime-config', 
     DOWNTIME_REVIEW : '/dashboard/tools/downtime-config-review',
     DOWNTIME_HOME : '/dashboard/tools/downtime',
     SCHEDULER_STAFF: '/dashboard/scheduler/staff',
     SCHEDULER_GLOABAL_SETTINGS : '/dashboard/scheduler/settings/:category',
     SCHEDULER_BULK_EDIT: '/dashboard/scheduler/staff-bulk/:category',
     SCHEDULER_BULK_EVENT: '/dashboard/scheduler/staff-bulk-edit-event/:event',
     SCHEDULER_BULK_CHANNEL_PARTNER: '/dashboard/scheduler/staff-bulk-edit-partner/:channerPartner',
     SCHEDULER_BULK_REGION: '/dashboard/scheduler/staff-bulk-edit-region/:regionID',
     SCHEDULER_ANALYSIS: '/dashboard/scheduler/analysis',
     SCHEDULER_USER_CONFIGURATION_ADD: '/dashboard/scheduler/staff/add',
     SCHEDULER_USER_CONFIGURATION_VIEW: '/dashboard/scheduler/staff/view/:id',
     SCHEDULER_USER_CONFIGURATION_EDIT: '/dashboard/scheduler/staff/edit/:id',
     PROJECT_HOME : '/dashboard/projects/project-list-new/detail/:id',
     CONTRACT_TEMPLATE_LIST : '/dashboard/contracts/templates',
     // CONTRACT_TEMPLATE_EDITOR : '/dashboard/contracts/templates-editor/:id',
     PROJECT_CONSUMPTION : '/dashboard/projects/project-list-new/consumption/:id',
     PROJECT_SOLAR_DESIGN : '/dashboard/projects/project-list-new/solar-design/:id',
     CONTRACT_TEMPLATE_EDITOR : '/dashboard/contracts/templates/templates-editor/:id',
     CONTRACT_CREATE_TEMPLATE_EDITOR : '/dashboard/contracts/templates-editor'

}
 
