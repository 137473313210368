import React, {useEffect, useState} from 'react';
import {BrowserRouter , useLocation} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'react-toastify/dist/ReactToastify.css';


// Styles scss
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/js/src/collapse.js";
import "./assets/scss/control-center.scss"
import "react-datepicker/dist/react-datepicker.css";

//configs
import {setColor, setPermissions, setModulePermissions} from './config';


//Interceptors
import SetupInterceptors from './utils/interceptors/AxiosInterceptor';
import "./utils/dropConsole"



//component
import Layout from './hoc/Layout'
import {PreLoader} from './layout'

import { loadDefault, setModules} from './store/actions';
import {useDispatch} from 'react-redux';


import { getPermissions, getModulePermisions, doLogin } from './services';
import {setSession} from './store/actions';
import HomePage from './pages/HomePage';


export const DefaultLanding = () => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const params = {
      avatar : query.get('avatar'),
      token : query.get('token')? JSON.parse(localStorage.gToken).id_token: '',
      username : query.get('username'),
      roles : query.get('roles')
    }
    if(params.token){
    doAuth(params, true)
    }else{
    invokeDefaultSettings();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const invokeDefaultSettings = () => {
    setColor();
    if(localStorage.userData){
      getRBACData().then((response) => {
      })
  }
 }

  const getRBACData = () => {
   return new Promise(resolve => {
    getModulePermisions().then((response) => {
      if(response.data.success){
        const list = [];
        response.data.data.forEach((item) => {
            list.push(item.module_slug)
        })
        setModulePermissions(list);
        dispatch(setModules(list))
      }else{
        setModulePermissions([]);
      }
     })
    getPermissions().then((response) => {
      if(response.data.success){
        setPermissions(response.data.data.permissions_list);
        dispatch(loadDefault());
        resolve(true);
      }else{
        setPermissions([]);
      }
    })
  });

 }

 const doAuth = (userData, legacy) =>{
  setColor();
  doLogin(userData, legacy).then((res) =>{
              if(res.data){
              localStorage.setItem('userData', JSON.stringify(res.data.data));
              localStorage.setItem('token', JSON.stringify(res.data.data.token));
              dispatch(setSession({data : res.data.data , isLoggedIn: true}));
              }
              getRBACData().then((response) => {
                dispatch(setSession({data : res.data.data , isLoggedIn: true}));
              })
  })
}

    return (
      <>
      <HomePage /> 
     </>
    )
}

const  App = () => {
  const history = createBrowserHistory();
  const [loader, setLoader] = useState(false);
  SetupInterceptors(history, setLoader);
  return (
    <>
    <BrowserRouter>
    <PreLoader loader={loader}/>
    <Layout>
      <DefaultLanding />
    </Layout>
    </BrowserRouter>
    </>
  );
}


export default App;
