import React from 'react';
import {InstallerProvider} from '../services/dashboard/installer/installerContext'

const InstallerLayout = (props) =>{
    return(
        <>
        <InstallerProvider>
        <div className="installer-layout">
            {props.children}
        </div>
        </InstallerProvider>
        </>
    )
}

export default InstallerLayout;