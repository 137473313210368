import axiosInstance from '../../utils/interceptors/AxiosInstance';
import { ENGINE_URL } from '../CONSTANTS';

export const getUserList = async(page, limit, search) => {
    return axiosInstance.get(ENGINE_URL + `acl/system-users-list?limit=${limit}&page=${page}&search_string=${search}`);  
    // return axiosInstance.get(ENGINE_URL + `acl/get-users-list`);
}
export const getAllRoles = async() => {
    return axiosInstance.get(ENGINE_URL + `acl-system/get-roles-list`);
}

export const getUserRoleList = async() => {
    return axiosInstance.get(ENGINE_URL + `acl-system/get-roles-list-for-user-list`);
}


export const getUserRoles = async(id) => {
    return axiosInstance.get(ENGINE_URL + `acl-system/get-role/${id}`)
}
// export const getUserRoles = async(id) => {
//     return axiosInstance.get(ENGINE_URL + `acl-system/get-role/${id}`)
// }

export const getSingleUserRole = async(id) => {
    // return axiosInstance.get(ENGINE_URL + `acl/get-users-list`);
    return axiosInstance.get(ENGINE_URL + `acl/get-user-details/${id}`);
}

export const getSingleUserPermissions = async(id) => {
    return axiosInstance.get(ENGINE_URL + `acl-system/get-permission/${id}`)
}

export const deleteRole = async(id) => {
    return axiosInstance.post(ENGINE_URL + `acl-system/delete-acl-role`, id)
}

export const getAllPermissions = async() => {
    return axiosInstance.get(ENGINE_URL + `acl-system/get-permissions-list`);
}

export const assignUserRole = async(data) => {
    return axiosInstance.post(ENGINE_URL + `acl/user-role-management`, data)
}

export const updateRolesPermissions = async(id,data) => {
    return axiosInstance.put(ENGINE_URL + `acl-system/update-role/${id}`, data)
}

export const createNewRole = async(data) => {
    return axiosInstance.post(ENGINE_URL + `acl-system/create-acl-role`, data);
}

export const deleteUser = async(data) => {
    return axiosInstance.post(ENGINE_URL + `acl/delete-system-user`, data)
}
export const userHistoryLog = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/userHistoryLogs`, data)
}
export const getfilteruser_log = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/getUserLogFilters`);
}