import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {LEAD_URL, ENGINE_URL } from '../../CONSTANTS';
import {showToast} from '../../../utils/components/toast'

///getChannelPartnerListDetails?{}
export const getProductList = async() => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(LEAD_URL + `getChannelPartnerListDetails?{}`)
            .then((res) => {
                if(res.data){
                }
                resolve(res.data.data)
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to get product data.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}

///productManagement/get_all_model?{}
export const getProductCategoryModelList = async() => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(ENGINE_URL + `productManagement/get_all_model?{}`)
            .then((res) => {
                if(res.data){
                }
                resolve(res.data.data)
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to get product data.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}


export const getAllCategery = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_all_category`)
}

export const getModelByCategoryId = async(id, fetch = false, inverterBuiltinFlag = false) => {
    const url = fetch ? '&fetchAll=true': '' + inverterBuiltinFlag ? '&inverter_type=built_in' :'';
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_all_model?category_id=${id}${url}`)
}
export const getSingleModelById = async(id) => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_model_by_id/${id}`)
}

export const getSingleModelUIById = async(id) => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_product/${id}`)
}

export const updateProductJSON = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/update_product`, data)
}

export const updateModelJSON = async(data) => {
    return axiosInstance.put(ENGINE_URL + `control-center/product-management/update_model`, data)
}

export const getModelJSONDefinition = async(id) => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_model_json?category_id=${id}`)
    //input field
}

export const getManufacturerById = async(id) => {
    return axiosInstance.get(ENGINE_URL + `productManagement/get_all_manufacturer?category_id=${id}`);
    //dropdown
}

export const getManufacturerByBattaryId = async(id) => {
    return axiosInstance.get(ENGINE_URL + `productManagement/get_all_manufacturer?category_id=${id=2}`);
    //dropdown
}
export const getManufacturerBySolarId = async(id) => {
    return axiosInstance.get(ENGINE_URL + `productManagement/get_all_manufacturer?category_id=${id=3}`);
    //dropdown
}

export const getAllManufacturerList = async(data) => {
    return axiosInstance.post(ENGINE_URL + 'control-center/product-management/get_all_manufacturer_list', data)
}

export const addProductModel = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/create_product`, data);
    //save
}
export const upload_assets = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/upload_assets`, data);
    //upload
}
export const upload_assets_Manufacturer = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/upload_manufacturer_assets`, data);
    //upload
}
export const getManufacturerList = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_all_manufacturer_list`);
}
export const getSingleManufacturerDetail = async(id) => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_manufacturer_new/${id}`);
}

export const deleteManufacturer = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/delete_manufacturer_new`, data)
}
export const restoreManufacturer = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/restore_manufacturer`, data)
}

export const getCountryList = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/product-management/get_country_list`);
}

export const createManufacturer = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/create_manufacturer_new`, data)
}

export const updateManufacturer = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/product-management/update_manufacturer_new`, data)
}
