import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CDN_URL } from "../../config";
import { establishCommunicationToSSO } from "../../utils/helper";

const Navbar = () => {
  const user = useSelector((state) => state.user);

  const logoutSession = () => {
    establishCommunicationToSSO({
      event: "logout",
    });
  };

  const Avatar = () => {
    return (
      <>
        <div className="avatar">
          <img
            src={user.payload?.data?.record?.extra_details.avatar}
            alt="avatar"
          />
          {user.payload?.data?.record?.name
            ? user.payload?.data?.record?.name
            : user.payload?.data?.data?.record?.name}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg fixed-top top-navigation">
          <div className="container-fluid">
            <a className="navbar-brand mb-0 h1" href="/">
              <img
                src={`${CDN_URL}partner_assets/electrum/electrum-logo.svg`}
                alt="logo"
              />
              <hr />
              Hubble
            </a>
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"></ul>
            {localStorage.token && (
              <form className="d-flex">
                <NavDropdown
                  title={Avatar()}
                  id="nav-dropdown"
                  style={{ textTransform: "capitalize" }}
                >
                  <NavDropdown.Item>Account</NavDropdown.Item>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => logoutSession()}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </form>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
