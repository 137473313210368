import * as api from '../../services'
import {GET_INSTALLER, GET_SERVICE_AREA_BY_INSTALLER,SET_APPROVAL_SYSTEM_TYPE} from '../CONSTANTS'

export const getInstallerList = (partner_key = '') => ({
    type: GET_INSTALLER,
    payload: api.getInstallerList(partner_key)
})
export const getServiceArea = (data) => ({
    type: GET_SERVICE_AREA_BY_INSTALLER,
    payload: api.getServiceAreaByInstaller(data)
})
export const setApprovalSystemType = (data) => ({
    type: SET_APPROVAL_SYSTEM_TYPE,
    payload: data
})