import React from 'react';
import {SchedulerProvider} from '../services/dashboard/scheduler/schedulerContext'

const SchedulerLayout = (props) =>{
    return(
        <>
        <SchedulerProvider>
        <div className="scheduler-layout">
            {props.children}
        </div>
        </SchedulerProvider>
        </>
    )
}

export default SchedulerLayout;