import {SET_USER, REMOVE_USER, GET_USER_LIST, SET_MODULE_PERMISSIONS} from '../CONSTANTS'

const initialState = {
    payload : {
        data : {},
        isLoggedIn : false,
        modulesPermission : []
    },
    userList : {}
}

export default function userReducer(state=initialState,action){
    switch(action.type){
        case SET_USER:
            return { ...state, payload : {...action.payload}}
        case REMOVE_USER:
            return { ...state, payload : {...action.payload}}  
        case GET_USER_LIST:
                return { ...state, userList : {...action.payload}}
        case SET_MODULE_PERMISSIONS :
               return { ...state, modulesPermission : action.payload}              
        default:
            return state
    }
}