import { combineReducers } from 'redux';
import installer from './installer_reducers';
import user from './user_reducers';
import data from './mixed_reducers';
import product from './product_reducers';
import maintenance from './maintenance_reducers';


const appReducers = combineReducers({
    installer,
    product,
    user,
    data,
    maintenance
})

export default appReducers;