/**
 * Context API used for Auth related information and methods.
 * I am keeping everything related to the authentication and PrivateRoute in this one file.
 */
 import React, { createContext } from 'react';
 import { useProvideAuth } from './UseProvideAuth';
 import {useSelector} from 'react-redux';
 import {isActionAllowedAuth} from '../../utils/helper';
 
 // Context API used for Auth related information and methods.
 const authContext = createContext();
 
 // Context Provider to wrap the whole app within and make auth information available.
 export function ProvideAuth({ children }) {
   const auth = useProvideAuth();
   return (
     <authContext.Provider value={auth}>
       {children}
     </authContext.Provider>
   );
 }
 
 // Custom hook to access auth related data and methods.
 // Most important hook to be used throughout
 export function useAuth() {
  const store = useSelector((state) => state);
  return store.user.payload.isLoggedIn;
  // return sessionStorage.token && sessionStorage.userData !== '';
 }

 export function canActivateRoute(permissions){
  return isActionAllowedAuth(permissions)
 }
 