import React, {memo} from 'react';
import * as MUI from '../../utils/components/mui'

const buttonStyle = {
  textTransform: 'capitalize',
  minWidth: '100px'
};

/**
 *
 * @param {handleClick, label, variant, buttonColor, component, startIcon, EndIcon,id} props 
 * variant as text/contained/outlined
 * buttonColor as secondary/primary/error/info/warning/Any custom color
 * component as span/p/div
 * startIcon , EndIcon as Icon at start/End
 * size as small/medium/large
 * @returns 
*/
const Button = (props) => ( 
  <MUI.Button
  id={props.id}
    className={`btn btn-default ${props.styleClass}`}
    style={buttonStyle}
    variant = {props.variant}
    color = {props.buttonColor}
    component = {props.component}
    onClick={props.handleClick}
    size = {props.size ? props.size : 'medium'}
    startIcon = {<i className={props.startIcon}></i>}
    disabled = {props.disabled} >
    {props.label}
  </MUI.Button>
);

export default memo(Button);