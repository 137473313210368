/**
 * This file is to handle unwanted console logs.
 * It is important to call this file at the beginning of the app.
 */
import '../config/other/signature';
 var console = (function (oldCons) {
    let drop = "true" === process.env.REACT_APP_DROP_CONSOLE ? true : false;
    let newCons = { ...oldCons };
    window.console = oldCons; // A quick handle to enable all console logs again. In browser > developer tools > console tab: {window.console = window.consol}
    if (drop) {
      oldCons.log(
        "%c                                 ",
        "font-size:50px; background: url(https://assets.solar.com/partner_assets/control-center/control-center-console.png) no-repeat; background-size: 300px 50px;",
      );
      newCons.log = function () {};
      newCons.warn = function () {};
      // newCons.error = oldCons.error; // You can retain error logs if required.
    }
    return newCons;
  })(window.console);
  window.console = console;
  