import { Autocomplete, DialogContent, Stack, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import {Button,showToast} from '../../../../../../layout';
import {useParams} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Roofplane from '../Roofplane';
import { DashboardContext } from '../../../../../../services/dashboard/dashboardContext';
import { getDesign, getDesignOptionValues, getprojectDetailsNotes, UpdateDesign,CreateDesign_api,Editdesign_api} from '../../../../../../services';
import { isEmpty } from 'lodash';
import Dialog from '../../../../../../layout/UI/dialog';
import CreateDesign from './createdesign';
import Editname from './editname';



function SolarDesign() {

const projectId = useParams();
const context = useContext(DashboardContext);
const [dropdownList, setDropdownList] = useState({
designerlist:[],
panel_list:[],
inverter_list:[],
roof_type_list:[]
})
const [Designdatalist,setDesigndatalist]=useState({
newDesignId:[],
panelGropData:[],
yearly_production:null,
designInfo:{},
imageData:[],
design_ID:null



})
const [text, setText] = useState("")
const [designerID, setDesignerID] = useState()
const [designerValue, setDesignerValue] = useState(null);
const [enableMarkAsCoomplete, setEnableMarkAsComplete] = useState(null);
const[name,setName]=useState('')
const[editname,seteditName]=useState('')
const [tabChange, settabChange] = useState(0);
const [tabs, setAddTab] = React.useState([]);
const [handleEditDesignName, setHandleEditDesignName] = useState('')
const [confirmPopup, setConfirmPopup] = useState({
isVisible: false,
title: "",
buttonText: ["CANCEL", "ADD"],
});

const [alertconfirmPopup, setAlertconfirmPopup] = useState({
isVisible: false,
title: "",
buttonText: ["CANCEL", "Save"],
});
//Adding name for create 
const nameChange=(e)=>{
setName(e.target.value)
}
//Edit name for update
const editdesignName=(e)=>{
seteditName(e.target.value)

}
//dropdown list
const dropdownlist = () => {
getDesignOptionValues().then((response) => {
if (response.data.success) {
setDropdownList({
...dropdownList,
designerlist:response?.data?.data?.designer_list,
panel_list:response?.data?.data?.panel_list,
inverter_list:response?.data?.data?.inverter_list,
roof_type_list:response?.data?.data?.roof_type_list
})
}
});
};
//design data flag=false
const Design=(data, tabIndex)=>{
getDesign(data).then((response) => {
if (response.data.success) {
setDesigndatalist({
...Designdatalist,
newDesignId:response?.data?.data?.other_designs,
panelGropData:response?.data?.data?.panel_groups,
yearly_production:response?.data?.data?.target_yearly_production,
designInfo:response?.data?.data?.design_info,
imageData:response?.data?.data?.design_images,
design_ID:response?.data?.data?.id

})
setDesignerID(response.data.data.designer_id)
seteditName(response.data.data?.name)
setEnableMarkAsComplete(response?.data?.data?.status)
context.setSystemDesign_roofpanel_values({
panel_id:response.data.data.design_info.panel_id,
inverter_id:response.data.data.design_info.inverter_id,
target_yearly_production:response.data.data.design_info.target_yearly_production,
base_panel_size:response.data.data.design_info.base_panel_size,
roof_type_id:response.data.data.design_info.roof_type_id,
stories:response.data.data.design_info.stories,
multiple_materials:0,
yearly_production:response.data.data.design_info.yearly_production
})
setAddTab(response.data.data?.other_designs)
if(tabIndex !== undefined){
settabChange(tabIndex)
setHandleEditDesignName(response.data.data?.other_designs[tabIndex].name);
}else{
setHandleEditDesignName(response.data.data?.other_designs[0].name);
}
} 
}).catch((error)=>showToast('ERROR',error?.response?.data?.message));
}
const getlist = () => {
getprojectDetailsNotes(projectId.id).then((response) => {
if (response.data.success) {
let data = []
response.data.data.forEach((element) => {
let channel = { ...element };
data.push(channel);
if(channel?.type==='design'){
setText(channel?.description);
}
});
}
});
};
useEffect(() => {
dropdownlist();
Design({
"project_id":projectId.id,
})
getlist()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(()=>{
let designerList = dropdownList?.designerlist.find((item)=>item.id===designerID);
setDesignerValue(designerList);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[designerID,dropdownList?.designerlist])

const changeHandler = (value) => {
setDesignerID(value.id)
}

const saveDesign = ()=>{
let payload = {
"design_id": Designdatalist?.design_ID,
"designer_id":designerID,
"design_info": context.state.systemDesign_roofpanel_values,
"design_images": context.state.systemDesign_imageArray
}

let flag=0;

if(payload.designer_id=== null){
flag=1;
}else if(payload){
if(payload.design_info.target_yearly_production === null || payload.design_info.base_panel_size===null || payload.design_info.roof_type_id===null || payload.design_info.stories === null){
flag = 1;
}else{
payload.design_images.forEach((item)=>{
if(item.key!=='dxf' && item.project_file_id===null){
flag=1
}
})
}
}

if(flag===1 || flag===undefined){
showToast('ERROR','Please fill the required * fields')
}else{
let data = context.state.roofplane_Details;
let updatedPayload = {...payload}
if((context.state.roofplane_Details)?.length>=1 && isEmpty(Designdatalist?.yearly_production)){
data?.forEach((item)=>{
if(item.panel_count!==null && item.tilt!==null && item.tsrf!==null && item.azimuth !== null && item.yearly_production!==null && item.roof_type_id !==null){
updatedPayload = {...updatedPayload, 'status':2}
}else{
updatedPayload = {...updatedPayload}
}
})
}

UpdateDesign(updatedPayload).then((response)=>{
if(response.data.success){
setEnableMarkAsComplete(response?.data?.data?.status)
showToast('SUCCESS','Data saved successfully')
}
}).catch((error)=>showToast('ERROR',error?.response?.data?.message))
}
}

//tabchange function 

const handleChange = (event, newValue) => {
Design({"project_id" :projectId.id ,"design_id":Designdatalist?.newDesignId[newValue].id},newValue);
};

//add tab function
//add text function
const addpanel=()=>{
CreateDesign_api({"project_id" :projectId.id,"name" : name }).then((response) => {
if (response.data.success) {
Design({"project_id" :projectId.id ,"design_id":response?.data.data?.id },tabs?.length);
setName('')
setConfirmPopup({
...confirmPopup,
isVisible: !confirmPopup.isVisible,
});
}
}).catch((error)=>showToast('ERROR',error?.response?.data?.message))
}

const markAsCompleted = () => {
context.setMarkAsComplete(2)
}

//edit text function
const addEditButtom = () => {
let tabIndex = tabs.findIndex((tab) => tab.id === Designdatalist?.design_ID);
Editdesign_api({"project_id" :projectId.id,"name" : editname ,"design_id":Designdatalist?.design_ID}).then((response) => {
if (response.data.success) {
console.log(response.data.data,"id")
Design({"project_id" :projectId.id ,"design_id":Designdatalist?.design_ID},tabIndex);
setAlertconfirmPopup({
...alertconfirmPopup,
isVisible: !alertconfirmPopup.isVisible,
});
}
}).catch((error)=>showToast('ERROR',error?.response?.data?.message))

};

return (
<div className='system-design'>
<h1 className='heading'>Designer</h1>
<div className='middle-part'>
<div className='left-side'>
<div className='left-dropdown'>
<Stack spacing={2}>
<Autocomplete
id="size-small-filled"
size="small"
disableClearable
options={dropdownList?.designerlist}
getOptionLabel={(option) => option.name}
value={designerValue}
popupIcon={<KeyboardArrowDownIcon sx={{ color: '#5029DD' }} />}
renderInput={(params) => (
<TextField
{...params}
variant="filled"
label={<p>Select Designer<span style={{color:'red'}}>*</span></p>}
placeholder=""

/>

)}
onChange={(e, value) => changeHandler(value)}
/>
</Stack>

</div>
<div id="hr_tag"></div>
<div className='button_1'>
<Button
variant="contained"
buttonColor="success"
styleClass="button-var2_header-left"
label="Open In Aurora"
></Button>
</div>

</div>
<div className='right-side'>
<div className="box_2">
<div className="box_content">
<p id="value">Yearly Consumption (kWh)</p>
<p id="dollar">{Designdatalist?.yearly_production}</p>
</div>
</div>
<div className='right-button_1'>
<Button
variant="contained"
buttonColor="success"
styleClass="button-var2_header-right"
label="Calculator"
></Button>
</div>
<div className='right-button_2'>
<Button
variant="contained"
buttonColor="success"
styleClass="button-var2_header-right_1"
label="Mark as Complete"
disabled={enableMarkAsCoomplete!==2}
handleClick={markAsCompleted}
></Button>
</div>

</div>
</div>
<div className='notes_field' >
<p className='tagline'>Notes for Design</p>
<textarea disabled
className='text'
id="outlined-helperText"
label="Sales"
autoComplete='off'
variant="filled"
rows="4" cols="50"
value={text}

InputLabelProps={{ shrink: true }}
inputProps={{
sx: {
"&::placeholder": {
color: "##6c757d"
}
}
}}
/>

</div>
<hr className='notes_hr' />
<div className='buttom-part'>

<Box sx={{ width: "100%" }}>
<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
<Tabs
value={tabChange}
onChange={handleChange}
aria-label="basic tabs example"
variant="scrollable"
>
{ 
tabs.map((tab,index)=>{
return(
<Tab key={index} 
icon={(tab.name === handleEditDesignName)?(<span title='Click to edit design name' onClick={() => { 
setAlertconfirmPopup({
...alertconfirmPopup,
isVisible: !alertconfirmPopup.isVisible,
title: "Edit Design name",
});}}><i className='icon-pencil1'></i></span>):''}
iconPosition="end" label={tab.name}
design_info={tab.design_info}
design_images={tab.design_images} index={index}
/> 
)
})} 
<span title='Click to create new design ' style={{marginTop:'20px' ,cursor:"pointer"}} value="tabProperties" onClick={() =>{
setConfirmPopup({
...confirmPopup,
isVisible: !confirmPopup.isVisible,
title: "Create Design",
})
}
}>{<CustomSvgIcon/>}</span> 
</Tabs>
</Box>
<div className="tab_panel">
{ 
tabs.map((tab,index)=>{
return<TabPanel key={index} label={tab.name} index={index} value={tabChange}>
<Roofplane 
panel_list={dropdownList?.panel_list}
inverter_list={dropdownList?.inverter_list} 
roof_type_list={dropdownList?.roof_type_list} 
panel_groups={Designdatalist?.panelGropData} 
design_ID={Designdatalist?.design_ID}
designInfo={Designdatalist?.designInfo}
imageData={Designdatalist?.imageData}
/>
</TabPanel>
})}
</div>
</Box>
</div>
<div className='buttom_footer'>
<Button
variant="contained"
buttonColor="success"
styleClass="button-var2_save"
label="Save"
disabled={context.state.disableSave}
handleClick={saveDesign}
></Button>

</div>
<Dialog
style={{maxWidth:"679px",Height:"239px"}}
className={'add_design'}
show={confirmPopup.isVisible}
version="second"
handleClose={() =>
setConfirmPopup({
...confirmPopup,
isVisible: !confirmPopup.isVisible,
})
}
buttonText={confirmPopup.buttonText}
saveChanges={addpanel}
title={confirmPopup.title}
>
{confirmPopup.bodyText}
<DialogContent className='add_design' style={{padding:"0px"}}>
<CreateDesign nameChange={nameChange} name={name}/>
</DialogContent>
</Dialog>
<>
<Dialog
style={{maxWidth:"679px",Height:"239px"}}
className={'add_design'}
show={alertconfirmPopup.isVisible}
version="second"
handleClose={() =>
setAlertconfirmPopup({
...alertconfirmPopup,
isVisible: !alertconfirmPopup.isVisible,
})
}
buttonText={alertconfirmPopup.buttonText}
saveChanges={addEditButtom}
title={alertconfirmPopup.title}
>
{confirmPopup.bodyText}
<DialogContent className='add_design' style={{padding:"0px"}}>
<Editname editdesignName={editdesignName} editname={editname}/>
</DialogContent>
</Dialog></>
</div>
)
}
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other}
>
{value === index && (
<Box sx={{ p: 3 }}>
<Typography>{children}</Typography>
</Box>
)}
</div>
);
}

export default SolarDesign
function CustomSvgIcon(props) {
return (
<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="32" height="32" rx="6" fill="#48BC25"/>
<g clip-path="url(#clip0_1795_70717)">
<path d="M17.1413 16.002H19.8993V17.388H17.1413V20.23H15.7553V17.388H12.9973V16.002H15.7553V13.16H17.1413V16.002Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1795_70717">
<rect width="9" height="18" fill="white" transform="translate(12 7)"/>
</clipPath>
</defs>
</svg>
);
}

