export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_APPROVE = "APPROVE";
export const CTA_REJECT = "REJECT";
export const CTA_CANCEL = "Cancel";
export const CTA_SAVE = "Save";

export const CDN_URL = "https://assets.solar.com/";
export const FILE_UPLOAD_AWS_API_KEY = "QUtJQTNSTVhFU1JBTUVMM0VRT0FiKzNiMGZONnBEcVltdHJBVTZ3TUtsUHd0K2VGeEVmdUdPZitOSzIw";

export const LANG = {
    SYSTEM_ERROR : "System error. Please try again later!",
    CTA_YES : "Yes",
    CTA_NO : "No",
    CTA_APPROVE : "APPROVE",
    CTA_REJECT : "REJECT",
    CTA_CANCEL : "Cancel",
    CTA_SAVE : "Save"
}