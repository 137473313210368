import axiosInstance from "./AxiosInstance";

const SetupInterceptors = (history,setLoader) => {
    axiosInstance.interceptors.request.use(
        // console.log(`${req.method} ${req.url} ${req}`);
        // Important: request interceptors **must** return the request.

        (req) => requestHandler(req),
        (error) => errorHandler(error)
    );
    axiosInstance.interceptors.response.use(function (response) {
        return responseHandler(response);
    }, function (error) {
        var status = error?.response?.status || '';
        if (status === 401) {
            localStorage.clear();
            window.location = '/login';
        }
       return errorHandler(error);
    });


    const requestHandler = request => {
        // Token will be dynamic so we can use any app-specific way to always   
        // fetch the new token before making the call
        if (request.headers["Authorization"]){
            if(request.loader) setLoader(true);
            return request;
        }
        else{
            const userData = localStorage.token || null;
            request.headers = {  
                 Authorization: 'Bearer ' + JSON.parse(userData),
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
                'X-Requested-With': 'XMLHttpRequest'
            }
            if(request.loader) setLoader(true);
            return request;
        }
      };
      
       const responseHandler = response => {
        setLoader(false);
        return response;
      };
      
      const errorHandler = error => {
        setLoader(false);
        return Promise.reject(error);
      };
}

export default SetupInterceptors;