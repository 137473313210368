import {
    SHOW_LOADER,
    HIDE_LOADER,
    ACTIVE_ROUTE,
    CHANNEL_PARTNER,
    SELECT_PARTNER,
    ACTIVE_BREADCRUMB
} from '../CONSTANTS'
import * as api from '../../services'

export const getChannelPartner = () => ({
    type: CHANNEL_PARTNER,
    payload: api.getChannelPartner()
})

export const selectChannelPartner = (partner) => ({
    type: SELECT_PARTNER,
    payload: partner
})

export const loading = (bool) => {
    return bool ? {
        type: SHOW_LOADER,
        payload: bool
    } : {
        type: HIDE_LOADER,
        payload: bool
    }
}

export const activeRoute = (title) => ({
    type: ACTIVE_ROUTE,
    payload: {
        title
    }
});

export const activeBreadcrumb = (title) => ({
    type: ACTIVE_BREADCRUMB,
    payload: {
        title
    }
});