import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { getSolutionData, getSolutionList, getAdders } from "../../../../../../services";
import { DashboardContext } from "../../../../../../services/dashboard/dashboardContext";
import { useParams } from "react-router-dom";

import { showToast } from "../../../../../../layout";

const System = lazy(() => import("./accordians/system"));
const Incentives = lazy(() => import("./accordians/incentives"));
const Adder = lazy(() => import("./accordians/adders"));
const Bidsselection = lazy(() => import("./solutionparts/bidsselection"));
const Middlepart = lazy(() => import("./solutionparts/middlepart_2"));
const Activebundle = lazy(() => import("./accordians/active_bundles"));

function Solutions() {
  const projectId = useParams();

  const [data, setdata] = useState({});
  const context = useContext(DashboardContext);

  const getsolution = (data) => {
    getSolutionData(data).then((response) => {
      if (response.data.success) {
        setdata(response.data);
        context.setSystem_deta(response.data.data);
        context.setPriorities(
          response.data.data.panel_groups.map((item) => {
            return item.priority;
          })
        );
        context.setEnergyProfileFields({
          ...context.state.energyProfileFields,
          avg_monthly_consumption:
            response.data.data?.energy_profile?.avg_monthly_consumption?.toFixed(
              2
            ),
          monthly_bill_adjustment:
            response.data.data?.energy_profile?.monthly_bill_adjustment?.toFixed(
              2
            ),
          monthly_kwh_adjustment:
            response.data.data?.energy_profile?.monthly_kwh_adjustment?.toFixed(
              2
            ),
          target_bill_offset:
            response.data.data?.energy_profile?.target_bill_offset?.toFixed(2),
          avg_monthly_bill:
            response.data.data?.energy_profile?.avg_monthly_bill?.toFixed(2),
          target_monthly_production:
            response.data.data?.energy_profile?.target_monthly_production?.toFixed(
              2
            ),
          target_yearly_production:
            response.data.data?.target_yearly_production?.toFixed(2),
        });
      }
    }).catch((error) => {
      showToast("ERROR", error?.response?.data?.message);
    });
  };

  const solutionList = (data) => {
    getSolutionList(data).then((response) => {
      if (response.data.success) {
        let val = response.data.data?.find((item)=>item?.in_view_selected===1)
        context.setSelectSolutionList({
          ...context.state.selectSolutionList, drpdownOptions: response.data?.data,
          value:val
        })
        getsolution({
          "project_id": data.project_id,
          "solution_id": val? val?.id : null
        });
        if(val !== undefined)
        getSolutionAdders({
          "project_id": data.project_id,
          "solution_id": val?.id
        });
      }
    }).catch((error) => {
      showToast("ERROR", error?.response?.data?.message);
    });
  };

const getSolutionAdders =(data)=>{
  getAdders(data).then((res)=>{
  if(res.data.success){
    context.setSolutionAddersResonse(res.data?.data);
  }
}).catch((error) => {
  showToast("ERROR", error?.response?.data?.message);
});
}

  useEffect(() => {
    solutionList({ project_id: projectId?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="SOLUTION">
      <Suspense fallback={<></>}>
        <Bidsselection
          projectData={data}
          getSolution = {getsolution}
          getAdders = {getSolutionAdders}
        ></Bidsselection>
      </Suspense>

      <hr className="notes_hr" />
      <Suspense fallback={<></>}>
        <Middlepart projectData={data}></Middlepart>
      </Suspense>
      <hr className="notes_hr" />
      <div className="according">
        <Suspense fallback={<></>}>
          <Activebundle></Activebundle>
        </Suspense>
        <Suspense fallback={<></>}>
          <System projectData={data}></System>
        </Suspense>

        <Suspense fallback={<></>}>
          <Incentives></Incentives>
        </Suspense>
        <Suspense fallback={<></>}>
          <Adder></Adder>
        </Suspense>
      </div>
    </div>
  );
}

export default Solutions;
