import React,{ Component } from 'react';
const SchedulerContext = React.createContext();


class SchedulerProvider extends Component {
    state = {
        timeZone: 'America/Los_Angeles',
        selectedUserProfileID: '',
        system_user_id:'',
        profile_status: '',
        displayTimesData : [],
        staffRoles: [],
        editUserData:{},
        filterData: {
            global: [],
            paused: [],
            qualified: [],
            staffRoles: [],
            staffProfileStatus: [],
        },
        staffList: [],
        quickSightRoleMapping : {
            'MANAGERCONSULTATIONS' : 'dae9b1d4-bdd4-4063-b485-2518b9ab7f1c',
            'SDRCONSULTATIONS' : 'e232072f-5d62-405c-8347-418eaec654c3',
            'EACONSULTATIONS' : '96af51be-4344-4f44-8899-ac182477d516'
        },
        activeTab : 'partner'
    }

    setSelectedUserProfileID = (id, data) => {
        this.setState({
            selectedUserProfileID : id,
            editUserData: data
        });
    }
    setSystemUserID=(id, profile_status) => {
        this.setState({
            system_user_id: id,
            profile_status: profile_status
        })
    }
    setDisplayTimesData = (data) => {
        this.setState({displayTimesData : data});
    }

    setFilterData = (data) => {
        this.setState({filterData : data});
    }

    setStaffList = (data) => {
        this.setState({staffList : data})
    }

    
    setStaffRoles=(data)=>{
        this.setState({staffRoles : data});
    };

    setActiveTab= (data) => {
        this.setState({activeTab : data});
    }
    
    render(){
        return(
            <SchedulerContext.Provider value={{
                state: this.state,
                setSelectedUserProfileID: this.setSelectedUserProfileID,
                setDisplayTimesData: this.setDisplayTimesData,
                setFilterData : this.setFilterData,
                setStaffList : this.setStaffList,
                setStaffRoles : this.setStaffRoles,
                setSystemUserID:this.setSystemUserID,
                setActiveTab: this.setActiveTab
            }}>
                {this.props.children}
            </SchedulerContext.Provider>
        )
    }
}


export {SchedulerContext,SchedulerProvider}