import { Autocomplete, Checkbox, Stack, TextField } from "@mui/material";
import React, { Suspense, useContext, useEffect, useState } from "react";
import Button from "../../../../../../layout/UI/button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as MUI from "../../../../../../utils/components/mui";
import { updateDesignFile, removeImage } from "../../../../../../services";
import downloadicon from "../../../../../../assets/images/Vector.png";
import { DashboardContext } from "../../../../../../services/dashboard/dashboardContext";
import Gridtable from "./gridTable";
import { showToast } from "../../../../../../layout";
import {useParams} from 'react-router-dom';


function Roofplane(props) {
  const projectId = useParams();
  const context = useContext(DashboardContext);
  const {
    panel_list,
    inverter_list,
    roof_type_list,
    panel_groups,
    design_ID,
    designInfo,
    imageData,
  } = props;
  const [panel_id, setPanel_id] = useState(null);
  const [inverter_id, setInverter_id] = useState(null);
  const [text, setText] = useState({
    value: null,
    validataion: false,
  });
  const [stories, set_stories] = useState({
    value: null,
    validataion: false,
  });
  const [roof_id, setRoof_id] = useState(null);
  const [target, setTarget] = useState(null);
  const [twodImage, setTwodimage] = useState({ id: null, fileSrc: null });
  const [threedImage, setThreedimage] = useState({ id: null, fileSrc: null });
  const [irradianceImage, setIrradianceImage] = useState({
    id: null,
    fileSrc: null,
  });
  const [dxFile, setDxFile] = useState({ id: null, fileSrc: null });
  const [checked, setChecked] = React.useState(0);
  const total_target_yearly_production =
    context.state.total_target_yearly_production;
  const SwitchhandleChange_1 = () => {
    setChecked(checked === 1 ? 0 : 1);
    context.setSystemDesign_roofpanel_values({
      ...context.state.systemDesign_roofpanel_values,
      multiple_materials: checked === 1 ? 0 : 1,
    });
  };

  useEffect(() => {
    let roofType = roof_type_list.find(
      (item) => item.id === designInfo?.roof_type_id
    );
    let inverterType = inverter_list.find(
      (item) => item.id === designInfo?.inverter_id
    );
    let panelType = panel_list.find((item) => item.id === designInfo?.panel_id);
    setPanel_id(panelType && panelType);
    setInverter_id(inverterType && inverterType);
    setRoof_id(roofType && roofType);
    setText({ value: designInfo?.base_panel_size });
    set_stories({ value: designInfo?.stories });
    setTarget(designInfo?.target_yearly_production);
    setChecked(designInfo?.multiple_materials);
  }, [designInfo, roof_type_list, inverter_list, panel_list]);

  useEffect(() => {
    let updatedArr = imageData?.map((obj) => {
      if (obj.project_file !== null) {
        return { key: obj.key, project_file_id: obj.project_file_id };
      } else {
        return { key: obj.key, project_file_id: null };
      }
    });
    context.setSystemDesign_imageArray(updatedArr);
    imageData?.forEach((element) => {
      if (element.key === "2d_image") {
        setTwodimage({
          id: element.project_file_id,
          fileSrc: element.project_file,
        });
      } else if (element.key === "3d_image") {
        setThreedimage({
          id: element.project_file_id,
          fileSrc: element.project_file,
        });
      } else if (element.key === "irradiance") {
        setIrradianceImage({
          id: element.project_file_id,
          fileSrc: element.project_file,
        });
      } else if (element.key === "dxf") {
        setDxFile({
          id: element.project_file_id,
          fileSrc: element.project_file,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData]);

  const handleClicked = (event, key) => {
    let FileData = (event.target || event.srcElement).files[0];
    const imageData = new FormData();
    imageData.append(`myfile`, FileData);
    imageData.append("project_id", projectId.id);
    imageData.append("type", key);
    updateDesignFile(imageData)
      .then((res) => {
        if (res.data.success) {
          let updatedArr = context.state.systemDesign_imageArray.map((obj) => {
            if (obj.key === key) {
              return {
                ...obj,
                project_file_id: res.data.data?.project_file_id,
              };
            } else {
              return obj;
            }
          });
          if (key === "2d_image") {
            setTwodimage({
              id: res.data.data.project_file_id,
              fileSrc: res.data.data.s3URL,
            });
          }
          if (key === "3d_image") {
            setThreedimage({
              id: res.data.data.project_file_id,
              fileSrc: res.data.data.s3URL,
            });
          }
          if (key === "irradiance") {
            setIrradianceImage({
              id: res.data.data.project_file_id,
              fileSrc: res.data.data.s3URL,
            });
          }
          if (key === "dxf") {
            setDxFile({
              id: res.data.data.project_file_id,
              fileSrc: res.data.data.s3URL,
            });
          }
          context.setSystemDesign_imageArray(updatedArr);
        }
      })
      .catch((error) => {
        showToast("ERROR", error?.response?.data?.message);
      });
  };

  const handleChange = (name, value) => {
    let re = /^-?([0-9]{1,})$/;
    if (name === "panel") {
      setPanel_id(value);
      context.setSystemDesign_roofpanel_values({
        ...context.state.systemDesign_roofpanel_values,
        panel_id: value.id,
      });
    }
    if (name === "selectInverter") {
      setInverter_id(value);
      context.setSystemDesign_roofpanel_values({
        ...context.state.systemDesign_roofpanel_values,
        inverter_id: value.id,
      });
    }
    if (name === "selectRoof") {
      setRoof_id(value);
      context.setSystemDesign_roofpanel_values({
        ...context.state.systemDesign_roofpanel_values,
        roof_type_id: value.id,
      });
    }
    if (name === "targetYearlyProduction") {
      setTarget(value);
      context.setSystemDesign_roofpanel_values({
        ...context.state.systemDesign_roofpanel_values,
        target_yearly_production: parseInt(value),
      });
    }
    if (name === "basePanelSize") {
      if (re.test(value)) {
        if (value <= 299 || value >= 1001) {
          setText({ value: value, validataion: true });
          context.setDisableSave(true);
        } else {
          context.setDisableSave(false);
          setText({ value: value, validataion: false });
          context.setSystemDesign_roofpanel_values({
            ...context.state.systemDesign_roofpanel_values,
            base_panel_size: parseInt(value),
          });
        }
      } else {
        context.setDisableSave(true);
        setText({ validataion: true });
      }
    }
    if (name === "stories") {
      if (re.test(value)) {
        if (value <= 0 || value >= 21) {
          set_stories({ value: value, validataion: true });
          context.setDisableSave(true);
        } else {
          context.setDisableSave(false);
          set_stories({ value: value, validataion: false });
          context.setSystemDesign_roofpanel_values({
            ...context.state.systemDesign_roofpanel_values,
            stories: parseInt(value),
          });
        }
      } else {
        context.setDisableSave(true);
        set_stories({ value: null, validataion: true });
      }
    }
  };

  const removeFile = (key, file) => {
    removeImage({ file_ids: [file.id] }).then((response) => {
      if (response.data.success) {
        let updatedArr = context.state.systemDesign_imageArray.map((obj) => {
          if (obj.key === key) {
            return { ...obj, project_file_id: null };
          } else {
            return obj;
          }
        });
        context.setSystemDesign_imageArray(updatedArr);
        if (key === "2d_image") {
          setTwodimage({ id: null, fileSrc: null });
        }
        if (key === "3d_image") {
          setThreedimage({ id: null, fileSrc: null });
        }
        if (key === "irradiance") {
          setIrradianceImage({ id: null, fileSrc: null });
        }
        if (key === "dxf") {
          setDxFile({ id: null, fileSrc: null });
        }
      }
    });
  };

  return (
    <>
      <div className="Roofplane">
        <div className="header_part">
          <div className="button_1">
            <Button
              variant="contained"
              buttonColor="success"
              styleClass="button-var2_header-left"
              label="Open In Aurora"
            ></Button>
          </div>
          <div className="button_2">
            <Button
              variant="contained"
              buttonColor="success"
              styleClass="button-var2_System-Data"
              label="Import Roof and System Data"
            ></Button>
          </div>
          <div className="button_2">
            <Button
              variant="contained"
              buttonColor="success"
              styleClass="button-var2_Rerun"
              label="Rerun"
            ></Button>
          </div>
          <div className="button_2">
            <Button
              variant="contained"
              buttonColor="success"
              styleClass="button-var2_Edit_Manually"
              label="Edit Manually"
            ></Button>
          </div>
        </div>
        <div className="dropdwon_part">
          <div className="panel-dropdown">
            <Stack spacing={2}>
              <Autocomplete
                id="size-small-filled"
                size="small"
                disableClearable
                options={panel_list}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : ""
                }
                value={panel_id}
                isOptionEqualToValue={(option, value) => option === value}
                popupIcon={<KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={<p>Select Panel</p>}
                    placeholder=""
                  />
                )}
                onChange={(e, value) => handleChange("panel", value)}
              />
            </Stack>
          </div>
          <div className="inverter_dropdown">
            <Stack spacing={2}>
              <Autocomplete
                id="size-small-filled"
                size="small"
                disableClearable
                options={inverter_list}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : ""
                }
                isOptionEqualToValue={(option, value) => option === value}
                value={inverter_id}
                popupIcon={<KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={<p>Select Inverter</p>}
                    placeholder=""
                  />
                )}
                onChange={(e, value) => handleChange("selectInverter", value)}
              />
            </Stack>
          </div>
          <TextField
            className="production_dropdown"
            name="targetYearlyProduction"
            label={
              <p>
                Target Yearly Production (kWh)
                <span style={{ color: "red" }}>*</span>
              </p>
            }
            variant="filled"
            value={target}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              handleChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="middle_part">
          <div>
            <TextField
              className="anualField_1"
              name="basePanelSize"
              style={text.validataion ? { border: "1px solid red" } : {}}
              label={
                <p>
                  Base Panel Size (W)<span style={{ color: "red" }}>*</span>
                </p>
              }
              variant="filled"
              value={text?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
            {text.validataion ? (
              <p
                style={{
                  color: "red",
                  fontFamily: "ActualR",
                  fontWeight: 400,
                  fontSize: "10px",
                }}
              >
                Value should be 300 to 1000
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="box_2">
            <div className="box_content">
              <p id="value">Yearly Production (kWh)</p>
              <p id="dollar">{total_target_yearly_production?.toFixed(2)}</p>
            </div>
          </div>
          <div className="roof_metarial_dropdown">
            <Stack spacing={2}>
              <Autocomplete
                id="size-small-filled"
                size="small"
                disableClearable
                options={roof_type_list}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : ""
                }
                isOptionEqualToValue={(option, value) => option === value}
                value={roof_id}
                popupIcon={<KeyboardArrowDownIcon sx={{ color: "#5029DD" }} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={
                      <p>
                        Roof Material<span style={{ color: "red" }}>*</span>
                      </p>
                    }
                    placeholder=""
                  />
                )}
                onChange={(e, value) => handleChange("selectRoof", value)}
              />
            </Stack>
          </div>
          <div className="checked">
            <Checkbox checked={checked} onChange={SwitchhandleChange_1} />
          </div>
          <p className="check_p">Mulitple Materials</p>
          <div>
            <TextField
              className="anualField_2"
              name="stories"
              label={
                <p>
                  Stories<span style={{ color: "red" }}>*</span>
                </p>
              }
              variant="filled"
              style={stories.validataion ? { border: "1px solid red" } : {}}
              // InputProps={{ inputProps: { min: 1, max: 20 } }}
              InputLabelProps={{
                shrink: true,
              }}
              value={stories.value}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
            {stories.validataion ? (
              <p
                style={{
                  color: "red",
                  fontFamily: "ActualR",
                  fontWeight: 400,
                  fontSize: "10px",
                  marginLeft: "16px",
                }}
              >
                Value should be 1 to 20
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <Suspense fallback={<></>}>
          <Gridtable
            panel_groups={panel_groups}
            roof_type_list={roof_type_list}
            design_ID={design_ID}
            roof_id={roof_id}
            checked={checked}
          />
        </Suspense>
        <div className="Footer_part">
          <div className>
            <p className="heding_footer">Images</p>
          </div>

          <div className="footer_middle_part">
            <div className="twodmain">
              <div className="box_1">
                <p className="text_type">
                  2D<span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image_linkA"
                    onChange={(data) => handleClicked(data, "2d_image")}
                    multiple
                  />
                  <MUI.Tooltip title="Files supported: PNG, JPG, JPEG">
                    <label
                      htmlFor="image_linkA"
                      style={{ marginLeft: "0px", color: "#fff" }}
                    >
                      <Button
                        variant="success"
                        buttonColor="primary"
                        component="span"
                        label="Browse"
                        styleClass="button-var2_1st"
                        //className="upload_button"
                      ></Button>
                    </label>
                  </MUI.Tooltip>
                </div>
              </div>
              {twodImage.fileSrc !== null ? (
                <div className="imageContainer">
                  <img
                    className="utilityImageStyles"
                    src={twodImage.fileSrc}
                    alt={twodImage.fileSrc}
                  />
                  <div className="downloadNremove">
                    <a href={twodImage.fileSrc} download>
                      <img
                        src={downloadicon}
                        className="downloadIcon"
                        title="Click to download"
                        alt="2dimage"
                      />
                    </a>
                    <i
                      className="icon-close electrum-font removeIcon"
                      title="Click to remove"
                      onClick={() => removeFile("2d_image", twodImage)}
                    ></i>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="threedmain">
              <div className="box_2">
                <p className="text_type">
                  3D<span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image_linkB"
                    onChange={(data) => handleClicked(data, "3d_image")}
                    multiple
                  />
                  <MUI.Tooltip title="Files supported: PNG, JPG, JPEG">
                    <label
                      htmlFor="image_linkB"
                      style={{ marginLeft: "0px", color: "#fff" }}
                    >
                      <Button
                        variant="success"
                        buttonColor="primary"
                        component="span"
                        label="Browse"
                        styleClass="button-var2_1st"
                        //className="upload_button"
                      ></Button>
                    </label>
                  </MUI.Tooltip>
                </div>
              </div>
              {threedImage.fileSrc !== null ? (
                <div className="imageContainer">
                  <img
                    className="utilityImageStyles"
                    src={threedImage.fileSrc}
                    alt={threedImage.fileSrc}
                  />
                  <div className="downloadNremove">
                    <a href={threedImage.fileSrc} download>
                      <img
                        src={downloadicon}
                        className="downloadIcon"
                        title="Click to download"
                        alt="3d_image"
                      />
                    </a>
                    <i
                      className="icon-close electrum-font removeIcon"
                      title="Click to remove"
                      onClick={() => removeFile("3d_image", threedImage)}
                    ></i>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="irradianceMain">
              <div className="box_3">
                <p className="text_type">
                  Irradiance<span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image_linkC"
                    onChange={(data) => handleClicked(data, "irradiance")}
                    multiple
                  />
                  <MUI.Tooltip title="Files supported: PNG, JPG, JPEG">
                    <label
                      htmlFor="image_linkC"
                      style={{ marginLeft: "0px", color: "#fff" }}
                    >
                      <Button
                        variant="success"
                        buttonColor="primary"
                        component="span"
                        label="Browse"
                        styleClass="button-var2_1st"
                        //className="upload_button"
                      ></Button>
                    </label>
                  </MUI.Tooltip>
                </div>
              </div>
              {irradianceImage.fileSrc !== null ? (
                <div className="imageContainer">
                  <img
                    className="utilityImageStyles"
                    src={irradianceImage.fileSrc}
                    alt={irradianceImage.fileSrc}
                  />
                  <div className="downloadNremove">
                    <a href={irradianceImage.fileSrc} download>
                      <img
                        src={downloadicon}
                        title="Click to download"
                        className="downloadIcon"
                        alt="irradianceImage"
                      />
                    </a>
                    <i
                      className="icon-close electrum-font removeIcon"
                      title="Click to remove"
                      onClick={() => removeFile("irradiance", irradianceImage)}
                    ></i>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="dxfMain">
              <div className="box_4">
                <p className="text_type">DFX File</p>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image_linkD"
                    onChange={(data) => handleClicked(data, "dxf")}
                    multiple
                  />
                  <MUI.Tooltip title="Files supported: PNG, JPG, JPEG">
                    <label
                      htmlFor="image_linkD"
                      style={{ marginLeft: "0px", color: "#fff" }}
                    >
                      <Button
                        variant="success"
                        buttonColor="primary"
                        component="span"
                        label="Browse"
                        styleClass="button-var2_1st"
                        //className="upload_button"
                      ></Button>
                    </label>
                  </MUI.Tooltip>
                </div>
              </div>
              {dxFile.fileSrc !== null ? (
                <div className="imageContainer">
                  <img
                    className="utilityImageStyles"
                    src={dxFile.fileSrc}
                    alt={dxFile.fileSrc}
                  />
                  <div className="downloadNremove">
                    <a href={dxFile.fileSrc} download>
                      <img
                        src={downloadicon}
                        title="Click to download"
                        className="downloadIcon"
                        alt="dxfimage"
                      />
                    </a>
                    <i
                      className="icon-close electrum-font removeIcon"
                      title="Click to remove"
                      onClick={() => removeFile("dxf", dxFile)}
                    ></i>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roofplane;
