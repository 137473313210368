import React from 'react';
import MainLayout from '../hoc/mainLayout';
import {useAuth} from '../navigation/Auth/ProvideAuth'
import RouterConfig from '../navigation/RouterConfig';


const HomePage = () =>{
    return(
        <>
        <MainLayout>
        <div style = {useAuth() ? OUTLET : {}} className="home-page">
         <RouterConfig />
        </div>
        </MainLayout>
        </>
    )
}

export default HomePage;

export const OUTLET = {
    'marginTop' :'56px'
}


