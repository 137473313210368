import React from 'react'

function CreateDesign(props) {
  return (
    <div className='crate-design'>
        <div>
        <p>Design Name</p>
        </div>
        <div>
                <input 
                    placeholder='Enter Design Name'
                    variant="filled"
                    autoComplete='off'
                    rows="4" cols="50"
                    value={props.name}
                    onChange={props.nameChange}
                    style={{
                      height:"32px",
                      border:"1px solid #AAAAAA",
                    width:'136px',
                    fontFamily:'ActualR',
                    fontWeight:"400",
                    borderRadius:"4px"
                    }}

                    
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          
                          padding:"0px"
                          ,margin:'0px',
                          height:"32px",
                          border:"1px solid #AAAAAA",
                        width:'136px',
                        fontFamily:'ActualR',
                        fontWeight:"400",
                        borderRadius:"4px"

                        }
                      }
                    }}
                  />


            </div>
    </div>
  )
}

export default CreateDesign