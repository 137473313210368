
import React from 'react';
// import { useSelector } from 'react-redux';
// import {LOADER} from '../../config/other/global'

export const PreLoader = (props) => {

  // const loading = useSelector((state) => state.data.loader);

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${props.loader ? "" : "show"}`}>
      <div className="loader1">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
    </div>
  );
};

// export default PreLoader;