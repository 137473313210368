import React, { Component } from "react";
import { calculateAdjustments } from "./tools/projectListService";

const DashboardContext = React.createContext();

class DashboardProvider extends Component {
  state = {
    roleList: [],
    activeRoute: "",
    utilityData: [],
    tariffData: [],
    zoneData: [],
    activeRouteTitle: "",
    rateSchedulesData: [],
    projectList: {
      selectedProjectId: "",
      list: "",
      searchText: "",
    },
    activeBreadcrumb: "",
    minimumMonthlyCharge: null,
    minimumMonthlyFee: null,
    consumptionUserDataLength: null,
    consumptionUserData: [],
    consumptionGenabilityData: [],
    genabilityCount: {
      totalCost: null,
      kwhSum: null,
    },
    disableSaveBtn: true,
    markAsComplete: 0,
    total_target_yearly_production: null,
    systemDesign_roofpanel_values: {
      panel_id: null,
      inverter_id: null,
      target_yearly_production: null,
      base_panel_size: null,
      roof_type_id: null,
      stories: 1,
      multiple_materials: 0,
      yearly_production: null,
    },
    systemDesign_imageArray: [],
    disableSave: false,
    roofplane_Details: [],
    system_data: {},
    priorities: [],
    energyProfileFields: {
      avg_monthly_consumption: null,
      monthly_bill_adjustment: null,
      monthly_kwh_adjustment: null,
      target_bill_offset: null,
      avg_monthly_bill: null,
      target_monthly_production: null,
      target_yearly_production: null,
    },
    solutionAddersResonse:{},
    selectSolutionList: {
      drpdownOptions: [],
      value:{}
    },
    solarAdders:{}
  };
  prepareRoleList = (user_roles) => {
    this.setState({ roleList: user_roles });
  };
  checkAuth = (user = []) => {
    let count = user.filter((role) => this.state.roleList.indexOf(role) !== -1);
    return count.length > 0;
  };
  setActiveRoute = (route) => {
    this.setState({ ...this.state, activeRoute: route });
  };
  setActiveRouteTitle = (route) => {
    this.setState({ ...this.state, activeRouteTitle: route });
  };
  setProjectListData = (data) => {
    this.setState({ projectList: { list: data, selectedProjectId: data.id } });
  };
  setActiveBreadcrumb = (data) => {
    this.setState({ activeBreadcrumb: data });
  };
  setSearchText = (data) => {
    this.setState({
      projectList: { ...this.state.projectList, searchText: data },
    });
  };
  setRateSchedulesData = (data) => {
    this.setState({ rateSchedulesData: data });
  };
  setMinimumMonthlyCharge = (value) => {
    this.setState({ minimumMonthlyCharge: value });
  };
  setMinimumMonthlyFee = (value) => {
    this.setState({ minimumMonthlyFee: value });
  };
  setUtilityData = (data) => {
    this.setState({ utilityData: data });
  };
  setTariffData = (data) => {
    this.setState({ tariffData: data });
  };
  setZoneData = (data) => {
    this.setState({ zoneData: data });
  };
  setConsumptionUserDataLength = (dataLength) => {
    this.setState({ consumptionUserDataLength: dataLength });
  };
  setConsumptionUserData = (data) => {
    this.setState({ consumptionUserData: data });
  };
  setConsumptionGenabilityData = (data) => {
    this.setState({ consumptionGenabilityData: data });
  };
  setGenabilityCount = (data) => {
    this.setState({
      genabilityCount: { totalCost: data.totalCost, kwhSum: data.kwhSum },
    });
  };
  setSaveDisableBtn = (flag) => {
    this.setState({ disableSaveBtn: flag });
  };
  set_total_target_yearly_production = (value) => {
    this.setState({ total_target_yearly_production: value });
  };
  setSystemDesign_roofpanel_values = (data) => {
    this.setState({
      systemDesign_roofpanel_values: {
        panel_id: data.panel_id,
        inverter_id: data.inverter_id,
        target_yearly_production: data.target_yearly_production,
        base_panel_size: data.base_panel_size,
        roof_type_id: data.roof_type_id,
        stories: data.stories,
        multiple_materials: data.multiple_materials,
        yearly_production: data.yearly_production,
      },
    });
  };
  setSystemDesign_imageArray = (data) => {
    this.setState({ systemDesign_imageArray: data });
  };
  setMarkAsComplete = (value) => {
    this.setState({ markAsComplete: value });
  };
  setDisableSave = (flag) => {
    this.setState({ disableSave: flag });
  };
  setRoofplane_deta = (data) => {
    this.setState({
      roofplane_Details: data,
    });
  };
  setSystem_deta = (data) => {
    this.setState({
      system_data: data,
    });
  };
  setPriorities = (data) => {
    this.setState({ priorities: data });
  };
  setEnergyProfileFields = (data) => {
    console.log("adjustments123", data);
    this.setState({
      energyProfileFields: {
        avg_monthly_consumption: data.avg_monthly_consumption,
        monthly_bill_adjustment: data.monthly_bill_adjustment,
        monthly_kwh_adjustment: data.monthly_kwh_adjustment,
        target_bill_offset: data.target_bill_offset,
        avg_monthly_bill: data.avg_monthly_bill,
        target_monthly_production: data.target_monthly_production,
        target_yearly_production: data.target_yearly_production,
      },
    });
  };

  setCalculatedAdjustments = (payload) => {
    calculateAdjustments(payload).then((res) => {
      if (res.status === 200) {
        console.log("adjustements", res.data);
        this.setState({
          energyProfileFields: {
            avg_monthly_consumption:
              res.data.avg_monthly_consumption?.toFixed(2),
            monthly_bill_adjustment:
              res.data.monthly_bill_adjustment?.toFixed(2),
            monthly_kwh_adjustment: res.data.monthly_kwh_adjustment?.toFixed(2),
            target_bill_offset: res.data.target_bill_offset?.toFixed(2),
            avg_monthly_bill: res.data.avg_monthly_bill?.toFixed(2),
            target_monthly_production:
              res.data.target_monthly_production?.toFixed(2),
            target_yearly_production:
              res.data.target_yearly_production?.toFixed(2),
          },
        });
      }
    });
  };

  setSolutionAddersResonse = (data) => {
    this.setState({solutionAddersResonse:data})
  };

  setSelectSolutionList = (data) => {
    this.setState({selectSolutionList:{
      drpdownOptions: data.drpdownOptions,
      value:data.value
    }
  })
  }

  setSolarAdders = (data) => {
    this.setState({solarAdders:data});
  }

  render() {
    return (
      <DashboardContext.Provider
        value={{
          state: this.state,
          prepareRoleList: this.prepareRoleList,
          checkAuth: this.checkAuth,
          setActiveRoute: this.setActiveRoute,
          setProjectListData: this.setProjectListData,
          setActiveBreadcrumb: this.setActiveBreadcrumb,
          setSearchText: this.setSearchText,
          setActiveRouteTitle: this.setActiveRouteTitle,
          setRateSchedulesData: this.setRateSchedulesData,
          setMinimumMonthlyCharge: this.setMinimumMonthlyCharge,
          setMinimumMonthlyFee: this.setMinimumMonthlyFee,
          setUtilityData: this.setUtilityData,
          setTariffData: this.setTariffData,
          setZoneData: this.setZoneData,
          setConsumptionUserDataLength: this.setConsumptionUserDataLength,
          setConsumptionUserData: this.setConsumptionUserData,
          setConsumptionGenabilityData: this.setConsumptionGenabilityData,
          setGenabilityCount: this.setGenabilityCount,
          setSaveDisableBtn: this.setSaveDisableBtn,
          set_total_target_yearly_production:
            this.set_total_target_yearly_production,
          setSystemDesign_roofpanel_values:
            this.setSystemDesign_roofpanel_values,
          setSystemDesign_imageArray: this.setSystemDesign_imageArray,
          setMarkAsComplete: this.setMarkAsComplete,
          setDisableSave: this.setDisableSave,
          setRoofplane_deta: this.setRoofplane_deta,
          setSystem_deta: this.setSystem_deta,
          setPriorities: this.setPriorities,
          setEnergyProfileFields: this.setEnergyProfileFields,
          setCalculatedAdjustments: this.setCalculatedAdjustments,
          setSolutionAddersResonse:this.setSolutionAddersResonse,
          setSelectSolutionList:this.setSelectSolutionList,
          setSolarAdders:this.setSolarAdders
        }}
      >
        {this.props.children}
      </DashboardContext.Provider>
    );
  }
}

export { DashboardContext, DashboardProvider };
