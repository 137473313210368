// A wrapper for <Route> that redirects to the login

import React, {Suspense, useState} from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth , canActivateRoute} from "./ProvideAuth";
import {PreLoader} from '../../layout';
import SideMenu from "../../layout/components/sidebar";
import Breadcrumb from '../../layout/components/Breadcrumb';

// screen if you're not yet authenticated.
function PrivateRouteWithSidebar({ component: Component, layout:Layout, redirect, permissions, showBreadcrumb, ...rest }) {
  const [toggle, setToggle] = useState(false);
  let auth = useAuth();
  return (
    <Route
    exact
      {...rest}
      render={(props) =>
        auth ? 
        canActivateRoute(permissions) ?
        (
          <>
           {sessionStorage.setItem('redirectFlag', false) }
            <div>
             <div className = "mainLayout-component" >
              <Layout>
             <SideMenu toggle={toggle} setToggle ={setToggle} />
             <div id="main" className = {toggle ? 'open-mleft' : 'close-mleft'} onClick={() => setToggle(false)}>
               <div>
            { !showBreadcrumb && <Breadcrumb toggle = {toggle} setToggle={setToggle} /> }
            </div>
            <Suspense fallback={<PreLoader loader={true}></PreLoader>}>
              
            <Component {...props} />
            </Suspense>
            </div>
            </Layout>
             </div>
           </div>
          </>
        ) : 
        redirect.status? <Redirect to = {redirect.component} />  : <Redirect to = '/dashboard' />
        : (
          <>
          {sessionStorage.setItem('redirectFlag', true) }
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
          </>

        )
      }
    />
  );
}

function PrivateRouteWithoutSidebar({ component: Component, layout:Layout, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        auth ? (
          <>
             <div className = "mainLayout-component" >
             <Component {...props} />
             </div>
          </>
        ) : (
          <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
          />
        )
      }
    />
  );
}

function AuthRoute({ component: Component, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        auth ? (
          <Redirect
            to= '/dashboard' />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
export {PrivateRouteWithSidebar, PrivateRouteWithoutSidebar,AuthRoute};
