import * as api from '../../services'
import {SET_USER, REMOVE_USER, GET_USER_LIST, SET_MODULE_PERMISSIONS} from '../CONSTANTS'

export const setSession = (userData, legacy = false) => ({
    type: SET_USER,
    // payload: {data : userData , isLoggedIn: true}
    payload : userData
})

export const loadDefault = () => ({
    type: SET_USER,
    payload: api.loadState()
})

export const setModules = (data) => ({
    type: SET_MODULE_PERMISSIONS,
    payload: data
})

export const logout = () => (
    {
    type: REMOVE_USER,
    // payload: {data : userData , isLoggedIn: true}
    payload : {data : {} , isLoggedIn: false}
})

export const getUserList = () => (
    {
    type: GET_USER_LIST,
    payload : api.getUserList()
})

