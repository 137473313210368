import React from 'react';
import NavBar from '../layout/components/Navbar'
import Footer from '../layout/components/footer'
// import SpeedDialTooltipOpen from '../layout/UI/speedDial'
import {DashboardProvider} from '../services/dashboard/dashboardContext';
import {useAuth} from '../navigation/Auth/ProvideAuth';

const MainLayout = (props) =>{
    const auth = useAuth();
    return(
        <>
        <DashboardProvider>
        {/* <SpeedDialTooltipOpen/> */}
        { auth && <NavBar /> }
        <div className={auth ? "mt-2" : ""}>
            {props.children}
        </div>
        { useAuth() &&<Footer /> }
        </DashboardProvider>
        </>
    )
}

export default MainLayout;