import React,{ Component } from 'react';
import {getConfigs} from '../../../services'

const MaintenanceContext = React.createContext();

class MaintenanceProvider extends Component {
    state = {
        timeInterval: 5,
        selectionType : 'scheduled',
        selectedPartners : [],
        downtimeData : {
        },
        configuratorData : {
            'schedule_from_date': new Date(),
            'schedule_to_date': new Date(),
            'popup_message': '',
            'attention_msg': '',
            'downtime_msg': ''
        },
        isConfigurationChanged : false,
        marketplace : {},
        downtimeHistory: {
            scheduled: {},
            unplanned: {}
        }
    }
    toggleSelectionType = (val) => {
        this.setState({selectionType: val})
    }
    checkBoxValidation = (obj) => {
        const count = obj.filter((item) => {
          return item.checked === false;
        })
        return count.length > 0;
      }
    checkDeselectBoxValidation = (obj) => {
        const count = obj.filter((item) => {
          return item.checked === true;
        })
        return count.length > 0;
      }
    setDowntimeData = (data) => {
      this.setState({downtimeData : data})
    }

    setConfiguratorData = (data) => {
        this.setState({configuratorData : data})
    }
    setSelectedPartners = (data) => {
        this.setState({selectedPartners : data})
    }
    setMarketplaces = (data) => {
        this.setState({marketplace : data})
    }
    setDowntimeHistory = (data, key, type) => {
        this.setState({downtimeHistory : {...this.state.downtimeHistory ,
                                            [type]: {
                                                ...this.state.downtimeHistory[type],
                                            [key]: data}}})
    }

    getConfig =(type, key, value) =>{
        getConfigs(type, key).then((configs) =>{
            console.log(configs, key, value)
            this.setDowntimeHistory(configs.data, key, value);
          });
    }
    setDefaultConfiguratorData = () => {
        this.setState({configuratorData : {
            'schedule_from_date': new Date(),
            'schedule_to_date': new Date(),
            'popup_message': '',
            'attention_msg': '',
            'downtime_msg': ''
        }})
    }

    compareDowntimeData = (data, value, partner_key) => {
       if(Object.keys(this.state.downtimeHistory[value][partner_key]).length > 0) {
        const count = Object.keys(this.state.downtimeHistory[value][partner_key]).filter((key) => {
            return (
             data[key] === this.state.downtimeHistory[value][partner_key][key] ? false : true
            )
        })
        return count.length !== 0;
       }
    else return false;
}
    filterPassedTime = (time, condition = new Date()) => {
        const currentDate = new Date(condition);
        const selectedDate = new Date(time);
        const todayDate = new Date();
    
        return todayDate.getTime() < currentDate.getTime() ? currentDate.getTime() < selectedDate.getTime():
        todayDate.getTime() < selectedDate.getTime();
    };

    setConfigurationStatus = (status) => {
        this.setState({isConfigurationChanged: status});
    }

    render(){
        return(
            <MaintenanceContext.Provider value={{
                state: this.state,
                toggleSelectionType: this.toggleSelectionType,
                checkBoxValidation: this.checkBoxValidation,
                checkDeselectBoxValidation: this.checkDeselectBoxValidation,
                setSelectedPartners :this.setSelectedPartners,
                setConfiguratorData: this.setConfiguratorData,
                setDowntimeData:this.setDowntimeData,
                setMarketplaces: this.setMarketplaces, 
                setDowntimeHistory : this.setDowntimeHistory  ,
                getConfigs: this.getConfig,
                setDefaultConfiguratorData: this.setDefaultConfiguratorData,
                compareDowntimeData: this.compareDowntimeData,
                filterPassedTime: this.filterPassedTime,
                setConfigurationStatus: this.setConfigurationStatus
            }}>
                {this.props.children}
            </MaintenanceContext.Provider>
        )
    }
}


export {MaintenanceContext, MaintenanceProvider}