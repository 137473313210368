import React from 'react';
import {ProductProvider} from '../services/dashboard/product/productContext'

const ProductLayoout = (props) =>{
    return(
        <>
        <ProductProvider>
        <div className="installer-layout">
            {props.children}
        </div>
        </ProductProvider>
        </>
    )
}

export default ProductLayoout;