import React from 'react';
import {UserProvider} from '../services/user/userContext'

const UserLayout = (props) =>{
    return(
        <>
        <UserProvider>
        <div className="user-layout">
            {props.children}
        </div>
        </UserProvider>
        </>
    )
}

export default UserLayout;