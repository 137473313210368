import {SHOW_LOADER,HIDE_LOADER, ACTIVE_ROUTE, CHANNEL_PARTNER,
    SELECT_PARTNER, ACTIVE_BREADCRUMB} from '../CONSTANTS'

const initialState = {
    loader : false,
    route : {
        title: 'Dashboard'
    },
    partners : [],
    selectedPartner : {}
};

export default function MixedReducer(state = initialState, action){
    switch (action.type) {
        case SHOW_LOADER:
            return {...state , loader :action.payload};
        case HIDE_LOADER:
            return {...state , loader :action.payload};
        case ACTIVE_ROUTE:
            return {...state, route : action.payload};
        case CHANNEL_PARTNER :
            return {...state, partners : action.payload};
        case SELECT_PARTNER :
            return {...state, selectedPartner : action.payload}; 
        case ACTIVE_BREADCRUMB :
            return {...state, activeBreadcrumb : action.payload};              
        default:
            return state;
    }
}


