import React, {useEffect, useContext, lazy, Suspense} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {getChannelPartner} from '../../../store/actions'
import {DashboardContext} from '../../../services/dashboard/dashboardContext';
import {MODULE} from '../../../config/roles'

const Cards = lazy(() => import('./cards'));
const Greeting = lazy(() => import('./greeting'));



const HomeDashboard = () =>{
    const context = useContext(DashboardContext)
    const storeData = useSelector((state) => state)
    let dispatch = useDispatch();

    useEffect(() => {
      if(storeData.data.partners.length === 0)
      dispatch(getChannelPartner());
    }, [dispatch, storeData.data.partners])

    useEffect(() => {
      context.prepareRoleList(storeData.user.payload.data.user_roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
    return(
        <>
        <div className = "home-dashboard">
        <div className="dashboard-landing">
        <Suspense fallback={<h1>Loader....</h1>}> 
        <Greeting name={storeData.user.payload?.data?.record?.name ? storeData.user.payload?.data?.record?.name : storeData.user.payload?.data?.data?.record?.name}
        ></Greeting>
        </Suspense>
        {
          storeData.user.modulesPermission &&
        <Suspense fallback={<h1>Loader....</h1>}>
        <Cards module = {MODULE}
        userData={storeData.user.payload?.data?.record ? storeData.user.payload?.data?.record : storeData.user.payload?.data?.data?.record}></Cards>
        </Suspense>
        }

        </div>
        </div> 
     </>
    )
}

export default HomeDashboard;