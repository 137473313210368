import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {ENGINE_URL } from '../../CONSTANTS';


export const getErrorList = async(data) => {
return axiosInstance.post(ENGINE_URL + `control-center/failed-jobs`,data); 
}
export const retryJob = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/retry-jobs`,data); 
}
export const errorSummaryRecords = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/failed-jobs-summary`,data); 
}