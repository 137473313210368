import {GET_PRODUCT, GET_PRODUCT_CATEGORY} from '../CONSTANTS'
export default function productReducer(state={},action){
    switch(action.type){
        case GET_PRODUCT:
            return { ...state, product_list : action.payload }
        case GET_PRODUCT_CATEGORY:
            return { ...state, category_model : action.payload }
        default:
            return state
    }
}