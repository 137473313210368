import React, {} from 'react';
import { DataGrid } from "@mui/x-data-grid";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  // GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridPaginationRowRangeSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';

var gridParams = {
  rowCount : 0,
  type : 'client',
  pageSize : '10'
}

 const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const paginationRowRange = useGridSelector(apiRef, gridPaginationRowRangeSelector);
  
    return (
      <div className="d-flex align-items-baseline">
          {
          gridParams.type === 'server' ?
          <p>Showing {(gridParams.pageSize*page) + 1} to {(gridParams.total - gridParams.pageSize*(page+1) >=0 ? gridParams.pageSize*(page+1) : gridParams.total)} of {gridParams.total} entries</p>
           :
          <p>Showing {paginationRowRange?.firstRowIndex+1} to {paginationRowRange?.lastRowIndex+1} of {apiRef.current.getAllRowIds().length} entries</p>
        }
       <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
      </div>
    );
  }
 
 function CustomToolbar() {
  return (
    <GridToolbarContainer className="grid-toolbar-cont">
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport  printOptions={{
    hideFooter: true,
    hideToolbar: true,
  }} />
    <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
} 

function CustomNoRowsOverlay() {
  return (
    <>No Data Available</>
  );
}

export default function Grid(props){
  gridParams = {
  total : props.rowCount,
  type : props.paginationMode === 'server' ? 'server' : 'client',
  pageSize : props.pageSize
}

const defaultValue = () => {
  return '';
}
  // console.log(props)
        return (
            props.paginationMode === 'server' ?
            <DataGrid columns={props.columns}
            rows={props.rows}
            pageSize={props.pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination 
            autoHeight = {true}
            onRowClick={props?.onRowClick}
            paginationMode="server"
            rowCount={props.rowCount}
            onPageChange = {(newPage) => props.onPageChange(newPage)}
            initialState = {props.initialState ? props.initialState : {}}
            getRowClassName = {props.getRowClassName ? props.getRowClassName : defaultValue()}
            localeText={{
              toolbarDensity: 'Size',
              toolbarDensityLabel: 'Size',
              toolbarDensityCompact: 'Small',
              toolbarDensityStandard: 'Medium',
              toolbarDensityComfortable: 'Large',
            }}
            components={{
              Pagination: props.pagination === false ? "" : CustomPagination,
              Toolbar: props.toolbar ? CustomToolbar: '',
              NoRowsOverlay: CustomNoRowsOverlay,
              Footer: props.footer
           }}
           componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },
           csvOptions: { allColumns: true } 
           }
           }}
           style={{cursor:'pointer'}}
           getRowHeight={({ densityFactor }) => {
            console.log('density',densityFactor,props.densityNum)

            if (props.densityNum && props.densityNum === 'auto') {
              return 'auto'; // Automatically adjust row height
            } else {
              return (props.densityNum || 60) * densityFactor; // Use the specified densityNum or a default value
            }
          }}
            {...props}
           /> 
           : 
           <DataGrid columns={props.columns}
           rows={props.rows}
           pageSize={props.pageSize}
           rowsPerPageOptions={[10, 20, 30]}
           pagination 
           autoHeight = {true}
           onRowClick={props?.onRowClick}
           initialState = {props.initialState ? props.initialState : {}}
           getRowClassName = {props.getRowClassName ? props.getRowClassName : defaultValue}
           localeText={{
             toolbarDensity: 'Size',
             toolbarDensityLabel: 'Size',
             toolbarDensityCompact: 'Small',
             toolbarDensityStandard: 'Medium',
             toolbarDensityComfortable: 'Large',
           }}
           components={{
             Pagination: props.pagination === false ? "" : CustomPagination,
             Toolbar: props.toolbar ? CustomToolbar: '',
             NoRowsOverlay: CustomNoRowsOverlay,
             Footer: props.footer
          }}
          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },
          csvOptions: { allColumns: true } 
          }
          }}
          getRowHeight={({ densityFactor }) => {
            console.log('density',densityFactor,props.densityNum)
            if (props.densityNum && props.densityNum === 'auto') {
              return 'auto'; // Automatically adjust row height
            } else {
              return (props.densityNum || 60) * densityFactor; // Use the specified densityNum or a default value
            }
          }}
          {...props}
          style={{cursor:'pointer'}}
          /> 
        );
}

