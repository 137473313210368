import axiosInstance from "../../../utils/interceptors/AxiosInstance";
import axios from "axios";
import { ENGINE_URL, HUBBLE_ENGINE_URL } from "../../CONSTANTS";

export const getProjectListConfig = async (page = 1, limit = 15, search) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/projects-list?page=${page}&limit=${limit}`,
    search
  );
};

export const getProjectFilesList = async (projectID) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/get-project-files`,
    projectID
  );
};

export const deleteFilesList = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/delete-project-files`,
    data
  );
};
export const Approvelist = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/v2/review-design-file`,
    data
  );
};
export const uploadFile = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/upload-project-files`,
    data
  );
};
export const uploadFileMulti = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/update-multi-project-file`,
    data
  );
};

export const updateProjectFiles = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/update-project-file`,
    data
  );
};

export const updateGdriveLink = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/project-gdrive-sync-status`,
    data
  );
};
export const getprojectListFilters = async () => {
  return axiosInstance.get(ENGINE_URL + `control-center/project-list-filters`);
};

export const getprojectFileListFilters = async () => {
  return axiosInstance.get(
    ENGINE_URL + `control-center/project-files-list-filters`);
};
export const getprojectDetails = async (id) => {
  return axiosInstance.get(
    HUBBLE_ENGINE_URL + `project/getProjectDetails/${id}`
  );
};
export const getprojectDetailsNotes = async (id) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `project/getProjectNotes/${id}`);
};

export const updateProjectNotes = async (data) => {
  return axiosInstance.post(
    HUBBLE_ENGINE_URL + `project/upsertProjectNotes`,
    data
  );
};
export const getprojectOptionValue = async () => {
  return axiosInstance.get(
    HUBBLE_ENGINE_URL + `project/getProjectOptionValues`
  );
};

export const UpdateProjectDetails = async (data) => {
  return axiosInstance.post(
    HUBBLE_ENGINE_URL + `project/updateProjectDetails`,
    data
  );
};

export const gethubbleInfo = async (id) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `consumption-page-info/${id}`);
};

export const getLses = async (id) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `lses/${id}`);
};

export const getTraiffs = async (id, lseId) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `tariffs/${id}/${lseId}`);
};

export const getTerritories = async (id, lseId) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `territories/${id}/${lseId}`);
};

export const setTariff = async (id, data) => {
  return axiosInstance.post(HUBBLE_ENGINE_URL + `set-tariff/${id}`, data);
};

export const setTerritory = async (id, data) => {
  return axiosInstance.post(HUBBLE_ENGINE_URL + `set-territory/${id}`, data);
};

export const updateAccountProperties = async (id, data) => {
  return axiosInstance.post(
    HUBBLE_ENGINE_URL + `update-account-properties/${id}`,
    data
  );
};

export const getConsumptionData = async (id) => {
  return axiosInstance.get(HUBBLE_ENGINE_URL + `consumption-data/${id}`);
};

export const updateConsumptionData = async (id, data) => {
  return axiosInstance.post(
    HUBBLE_ENGINE_URL + `update-consumption-data/${id}`,
    data
  );
};

export const updateKwAdjustment = async (data) => {
  return axiosInstance.post(
    HUBBLE_ENGINE_URL + `consumption-monthlyKwhAdjustment`,
    data
  );
};

export const getGenabilityAccountData = async (data) => {
  return axiosInstance.post(ENGINE_URL + `gs/account/get`, data);
};

export const getAllConsumptionData = async (id) => {
  const urls = [
    HUBBLE_ENGINE_URL + `consumption-page-info/`,
    HUBBLE_ENGINE_URL + `consumption-data/`,
    HUBBLE_ENGINE_URL + `lses/`,
  ]; // array of API endpoints
  const responses = await axios.all(
    urls.map((url) => axiosInstance.get(url + id))
  ); // fire API calls asynchronously
  return responses; // extract response data
};
export const updateDesignFile = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/uploadDesignFile`, data);
};
export const getDesignOptionValues = async () => {
  return axiosInstance.get(ENGINE_URL + `hubble/getDesignOptionValues`);
};
export const getDesign = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/getDesign`, data);
};
export const UpdateDesign = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/updateDesign`, data);
};

export const uploadHourlyProduction = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `hubble/uploadHourlyProductionFile`,
    data
  );
};

export const upsertPanelGroup = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/upsertPanelGroup`, data);
};

export const removeImage = async (data) => {
  return axiosInstance.post(
    ENGINE_URL + `control-center/delete-project-files`,
    data
  );
};
export const getSolutionData = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/getSolution`, data);
};

export const getSolutionList = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/getSolutionList`, data);
};

export const calculateAdjustments = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/calculateAdjustments`, data, {loader:false});
};

export const CreateDesign_api = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/createDesign`, data);
};
export const Editdesign_api = async (data) => {
  return axiosInstance.post(ENGINE_URL + `hubble/renameDesign`, data);
};

export const getAdders = async (data) => {
  return axiosInstance.get(ENGINE_URL + `hubble/getSolutionAdders/${data?.project_id}/${data?.solution_id}` )
}

export const saveAdders = async (data,payload) => {
  return axiosInstance.post(ENGINE_URL + `hubble/saveAddersData/${data?.project_id}/${data?.solution_id}`,payload)
}

export const calculateBids = async (payload) => {
  return axiosInstance.post(ENGINE_URL + `hubble/calculateBids`,payload);
}