// import { showToast } from '../../../layout';
import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import { ENGINE_URL } from '../../CONSTANTS';

export const getStaffConfigData = async (data ={}) => {
    return axiosInstance.post(ENGINE_URL + `control-center/staff-list`,{search: data});

}

export const getGlobalSettingsEventTypes = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/getEventTypes`);
}

export const handleStaffProfileFlags = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/handle-staff-profile-flags`, data)
}

export const updateGlobalSettingsEventTypes = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/updateEventTypes`, data);
}
export const setUploadPhotoConfig = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/upload-profile-photo`, data);
}

export const setProfileInfoConfig = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/save-staff-profile`, data);
}

export const deleteStaffProfile = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/staff-delete-status`, data);
}

export const getStaffFilterData = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/get-staff-list-filters`);
}

//Region Screen

export const addRegion = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/addRegion`, data);
}

export const getRegion = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/getRegions`);
}

export const deleteRegion = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/deleteRegion`, data);
}

export const getStateRegionGroupping = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/getStateRegionGrouping`);
}

export const updateStateRegionGroupping = async (data) => {
    return axiosInstance.put(ENGINE_URL + `control-center/updateStateRegionGrouping`, data);
}

//Availability

export const getAvailability = async (data = {}) => {
    return axiosInstance.post(ENGINE_URL + `control-center/availability-list`, data);
}

export const getAvailabilityListGroupDetail = async (id) => {
    return axiosInstance.get(ENGINE_URL + `control-center/availability-list-group-details/${id}`);
}

export const alertTriggerUpdate = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/alert-trigger-edit`, data);
}

export const getProfileInfoByIDConfig = async (id) => {
    return axiosInstance.post(ENGINE_URL + `control-center/get-staff-profile`, { 'profile_id': id });
}

export const getAvailabilityFilter = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/availability-list-filters`);
}

export const getStaffRoles = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/getStaffRoles`);
}

export const addStaffRole = async (role) => {
    return axiosInstance.put(ENGINE_URL + `control-center/addStaffRole`, { 'staff_role': role });
}

// Analysis

export const getAnalysis = async (data, url) =>{
    return axiosInstance.post(ENGINE_URL + `control-center/${url}`, data)
}

export const getQuickSightDashboard = async (id, data) => {
    return axiosInstance.post(ENGINE_URL + `quicksight/getURL`, data)
}

// Configure

export const getStaffConfigDetails = async (id) => {
    return axiosInstance.post(ENGINE_URL + `control-center/getStaffConfigDetails`, {'system_user_id': id });
}

export const setStaffConfigDetails = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/updateStaffConfigDetails`, data);
}


export const getReportingManagerDetails = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/getReportingManagerEmailsList`);
}

export const getStaffConfigLogs = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/staff-config-logs`, data)
}
export const getStaffBulkEventType = async () => {
    return axiosInstance.get(ENGINE_URL + `control-center/getEventBlockerTexts`);
}
export const setStaffBulkEventType = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/updateEventBlockerTexts`, data);
}
export const getStaffConfigList = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/getECRConfigList`, data);
}
export const getStaffUsersConfigs = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/getECRStaffUsersConfigs`, data);
}
export const getStaffbulkUpdateConfigs = async (data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/bulkUpdateConfigs`, data);
}