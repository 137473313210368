import React from 'react';
import {ToolProvider} from '../services/dashboard/tools/toolsContext'

const ToolsLayout = (props) =>{
    return(
        <>
        <ToolProvider>
        <div className="installer-layout">
            {props.children}
        </div>
        </ToolProvider>
        </>
    )
}

export default ToolsLayout;