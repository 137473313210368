// Authenticate
export const SET_USER = 'set_user'
export const REMOVE_USER = 'remove_user'
export const GET_USER_LIST = "get_user_list"
export const SET_MODULE_PERMISSIONS = "set_module_permissions"


// installer
export const GET_INSTALLER = 'get_installer'
export const GET_SERVICE_AREA_BY_INSTALLER = 'service_area_by_installer'
export const SET_APPROVAL_SYSTEM_TYPE = "set_approval_system_type"

// product
// installer
export const GET_PRODUCT = 'get_product'
export const GET_PRODUCT_CATEGORY = 'get_product_category_list'


//loader
export const SHOW_LOADER = 'show_loader'
export const HIDE_LOADER = 'hide_loader'
export const ACTIVE_ROUTE = 'activate route'
export const ACTIVE_BREADCRUMB = 'active_breadcrumb'


// Channel Partner
export const CHANNEL_PARTNER = 'get_channel_partner_list'
export const SELECT_PARTNER = 'channel_partner_selected'
export const SELECT_TYPE = 'Select Schedule Type'


//Maintenance
export const SAVE_PARTNERS = 'Save Selected Channel Partner'
export const SCHEDULE_DOWNTIME = 'Scheduled Maintenance'
export const UPLOAD_FIlE = 'Upload Downtime File'
