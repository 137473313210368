import React,{ Component } from 'react';

const UserContext = React.createContext();


class UserProvider extends Component {
    state = {
        users:[],
        active:true,
        userRoles:[],
        allRoles: [],
        userPermissions:[],
        userDetail:{}
    }

  /**
   * Node tree data structure
   *  userRoles:[
        {
            value: 'downtime-config-admin',
            label: 'Downtime Config Admin',
            children : [
                {
                value: 'edit-downtime-config',
                label: 'Edit Downtime Config'
                }
            ]
        },
    ],
   *  */ 

    toggleActive = () => {
        this.setState({active:!this.state.active})
    }
    updateUsers = (data) => {
        this.setState({users : data})
    }

    updateRoles = (userRoles) => {
        const data = [];
        this.setState({allRoles : userRoles})
        // eslint-disable-next-line array-callback-return
        userRoles.map((role, index) => {
           data.push({
            label : role.info.display_name,
            value : String(role.id),
           })
        })
        this.setState({userRoles : data});
                // console.log(this.state)

    }

    updatePermissions = (roles) => {
        const data = [];

        // eslint-disable-next-line array-callback-return
        roles.map((role, roleIndex) => {
            data.push({
             label : role.module_name,
             value : String(role.acl_module_id) + 'module',
            })
            if(role.permissions.length > 0){
             data[roleIndex].children = [];
             // eslint-disable-next-line array-callback-return
             role.permissions.map((permission, permissionIndex) => {
                 data[roleIndex].children.push({
                 value : permission.id,
                 label : permission.info.display_name
                 });
             })
        }
         })
         this.setState({userPermissions : data});
        // this.setState({allPermissions : permissions})
        // // eslint-disable-next-line array-callback-return
        // permissions.map((permission, index) => {
        //    data.push({
        //     label : permission.info.module_slug,
        //     value : String(permission.acl_module_id),
        //    })
        // })
        // this.setState({userPermissions : data});
    }

    updateSingleUserDetail = (role, id) => {
        // console.log(role, id);
        const data = {
            userId: id,
            checkedItem: [],
            permissions: [],
            roles:[]
        };
        // eslint-disable-next-line array-callback-return
          role[0].acl_roles.map((roles, index) => {
            data.checkedItem.push(String(roles.id));
            data.permissions.push({
                label : roles.info.display_name,
                value : String(roles.id),
            });
            if(roles.acl_permissions.length > 0){
                data.permissions[data.permissions.length-1].children = [];
            // eslint-disable-next-line array-callback-return
            roles.acl_permissions.map((permission, index) => {
                data.permissions[data.permissions.length-1].children.push({
                value : String(roles.id) + '+' + String(permission.id),
                label : permission.info.display_name
                });
            })
           }
          })
        this.setState({userDetail:data});
        // console.log(this.state)

          // data.map((role, index) => {
        //     data[index].label = role.info.display_name;
        //     data[index].value = String(role.id);
        //     permissions.push({
        //         label : role.info.display_name,
        //         value : String(role.id),
        //     });
        //     if(role.acl_permissions.length > 0){
        //         permissions[index].children = [];
        //         // eslint-disable-next-line array-callback-return
        //         role.acl_permissions.map((permission) => {
        //             // permission.value = String(role.id) + '+' + String(permission.id)
        //             // permission.label = permission.info.display_name;
        //             permissions[index].children.push({
        //             value : String(role.id) + '+' + String(permission.id),
        //             label : permission.info.display_name
        //             });
        //         })
        //     }
    }


    render(){
        return(
            <UserContext.Provider value={{
                state: this.state,
                updateUsers: this.updateUsers,
                updateRoles: this.updateRoles,
                updateSingleUserDetail: this.updateSingleUserDetail,
                updatePermissions: this.updatePermissions
            }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}


export {UserContext,UserProvider}