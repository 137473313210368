import axiosInstance from '../../utils/interceptors/AxiosInstance';
import {LEAD_URL, ENGINE_URL } from '../CONSTANTS';
import {showToast} from '../../utils/components/toast'

export const getChannelPartner = async() => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(LEAD_URL + `getChannelPartner?{}`)
            .then((res) => {
                // console.log(res);
                if(res.data){
                }
                resolve(res.data.data)
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to get channel partner list.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}


// getInstallerList?channel_partner_key=&page=1&{}
export const getInstallerList = async(partner) => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(LEAD_URL + `getInstallerList?channel_partner_key=${partner?.api_key ? partner?.api_key : '' }&page=1&{}`)
            .then((res) => {
                if(res.data){
                    if(partner !== ''){
                        showToast('INFO' ,`Data updated according to selected ${partner.name} channel partner.`)
                    }
                }
                resolve(res.data.data)
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to get installer data.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}

//getServiceAreaByInstaller
export const getServiceAreaByInstaller = async(data) => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.post(LEAD_URL + `getServiceAreaByInstaller`, data)
            .then((res) => {
                if(res.data){
                }
                resolve(res.data.data)
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to get installer data.`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}

export const getModulePermisions = async() => {
    // return new Promise((resolve, reject) => {
    //     try {
    //         axiosInstance.get(ENGINE_URL + `acl/get-user-permission-details`)
    //         .then((res) => {
    //             if(res.data){
    //             }
    //             const list = [];
    //             // eslint-disable-next-line array-callback-return
    //             res.data.data.map((item, index) => {
    //                 list.push(item.module_slug)
    //             })
    //             resolve(list)
    //         }).catch ((err) => {
    //             showToast('ERROR' ,`Failed to fetch user permissions data.`)
    //             reject('Unable to fetch user permissions')
    //         });
 
    // }
    //  catch (err) {
    //      resolve('system_error');
    //  }

    //  }) 
    return axiosInstance.get(ENGINE_URL + `acl/get-user-permission-details`);
}
 export const getPermissions = async() => {
    // return axiosInstance.get(ENGINE_URL + `acl/get-user-permission-details`);
    return axiosInstance.get(ENGINE_URL + `acl/get-user-details`);
}
