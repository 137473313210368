import * as api from '../../services'
import {GET_PRODUCT, GET_PRODUCT_CATEGORY} from '../CONSTANTS'

export const getProductList = () => ({
    type: GET_PRODUCT,
    payload: api.getProductList()
})

export const getCategoryModelList = () => ({
    type: GET_PRODUCT_CATEGORY,
    payload: api.getProductCategoryModelList()
})