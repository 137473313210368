/**
 * Export all the layout elements here
 */
import {lazy} from 'react';

//Export all the UI components Here
export {showToast} from './UI/toast';
export {PreLoader} from './UI/PreLoader';
export {ScrollToTop} from './UI/ScrollToTop';
export const Button  = lazy(() => import('./UI/button'))
export const Dialog  = lazy(() => import('./UI/dialog'))
export const FullScreenDialog  = lazy(() => import('./UI/dialogFullscreen'))
export const Table  = lazy(() => import('./UI/table'))
export const ToggleSwitch  = lazy(() => import('./UI/toggle'))
export const LongMenu  = lazy(() => import('./UI/hamburger_menu'))
export const DropdownFilter = lazy(() => import('./UI/dropdown_with_filter'))
export const Grid  = lazy(() => import('./UI/dataGrid'));
export const WidgetTree  = lazy(() => import('./UI/checkboxTree'))





//Export all the layout components Here
// export {Breadcrumb} from './components/Breadcrumb';
// export {Footer} from './components/footer';
// export {Navar} from './components/Navbar';
// export {SideMenu} from './components/sidebar';
export const TinyEditorComponent  = lazy(() => import('./components/tinyEditor'))



