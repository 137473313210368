import React,{ Component } from 'react';

const ToolContext = React.createContext();

class ToolProvider extends Component {
    state = {
        productConfigurator : {},
        projectList : {
            selectedProjectId: '',
            projectList: {}
        },
        consumptionUserData:undefined
        
    }
    setProjectListData = (data) => {
        this.setState({ projectList: {...this.state.projectList, projectList : data}})
    }
    setProjectSelected = (data) => {
        this.setState({ projectList: {...this.state.projectList, selectedProjectId : data}})
    }
    setConsumptiponUserData = (data) => {
        this.setState({consumptionUserData:[data]})
    }
    render(){
        return(
            <ToolContext.Provider value={{
                state: this.state,
                setProjectListData: this.setProjectListData,
                setProjectSelected: this.setProjectSelected,
                setConsumptiponUserData: this.setConsumptiponUserData
            }}>
                {this.props.children}
            </ToolContext.Provider>
        )
    }
}


export {ToolContext, ToolProvider}