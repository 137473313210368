import React from "react";
import { GoogleLogin } from "react-google-login";
import * as LAYOUT from "../../layout";
import { CDN_URL } from "../../config";
import { establishCommunicationToSSO } from "../../utils/helper";

const Login = () => {
  const onSuccess = (res) => {
    establishCommunicationToSSO({
      event: "login",
      token: res?.tokenObj?.id_token,
      avatar: res?.profileObj?.imageUrl,
    });
  };

  const onFailure = (res) => {
    LAYOUT.showToast("ERROR", `Failed to login.`);
  };

  return (
    <>
      <div className="outer-cont">
        <div className="d-flex justify-content-center login-container">
          <img
            src={`${CDN_URL}partner_assets/electrum/electrum-logo.svg`}
            alt="logo"
          />
          <div className="cont-log">
            <h2>Welcome to Hubble!</h2>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_ID}
              buttonText="Login With Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              style={{ marginTop: "100px", width: "200px" }}
              isSignedIn={false}
            />
            <p className="copy">
              Copyright © {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
