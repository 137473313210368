import * as api from '../../services'
import {SAVE_PARTNERS, SCHEDULE_DOWNTIME, UPLOAD_FIlE, SELECT_TYPE} from '../CONSTANTS'

export const saveSelectedPartners = (partners) => ({
    type: SAVE_PARTNERS,
    payload: partners
})

export const scheduleDowntime = (data) => ({
    type: SCHEDULE_DOWNTIME,
    payload: data
})

export const uploadDowntimeFile = (formData, key) => ({
    type: UPLOAD_FIlE,
    payload : api.uploadDowntimeFile(formData, key)
})
export const selectScheduleType = (val) => ({
    type: SELECT_TYPE,
    payload : val
})