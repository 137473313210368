import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BREADCRUMB_NAME } from "../../config";
import {useSelector} from 'react-redux';

const BreadCrumb = (props) => {
  const store = useSelector(store => store.data)
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [routeModule, setRouteModule] = useState([]);
  useEffect(() => {
    const path = pathname.split("/");
    setRouteModule(path.splice(0, 3));
    if(path[path.length-1] >= 0) {path.pop() ;setData(path)}
    else setData(path);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getBreadcrumbText = (item) => {
    return BREADCRUMB_NAME[routeModule[2]]?.[item]
      ? BREADCRUMB_NAME[routeModule[2]][item].name
      : "-";
  };

  const getBreadcrumbLink = (item) => {
    return BREADCRUMB_NAME[routeModule[2]]?.[item]? BREADCRUMB_NAME[routeModule[2]][item].redirect
      : "-";
  };

  const getCustomizedText = (text, item) => {
    switch (text){
      case 'add': return 'Add'; 
      case 'view' : return 'View';
      case 'edit' : return 'Edit';
      default : return '-'
    }
  }

  const getBreadcrumb = (item, index) => {
    // console.log(ACTIVE_BREADCRUMB_NAME)
    if (index < data.length - 1) {
      return (
        <NavLink key={index} to={getBreadcrumbLink(item)} className="breadcrumb-item">
          {item === "project-list" || data[index-2] === 'channel-mapping'
            ? store.activeBreadcrumb ? store.activeBreadcrumb.title : item
            : getBreadcrumbText(item)}
        </NavLink>
      );
    } else {
      return (
        <Breadcrumb.Item
          key={index}
          className={`breadcrumb-item ${index === data.length - 1 ? "active": ""}`}>
          {item >= 0
            ? getCustomizedText(data[index-1], item)
            : data[index-2] === 'channel-mapping'? (store.activeBreadcrumb ? store.activeBreadcrumb.title : 'Details'): getBreadcrumbText(item)}
        </Breadcrumb.Item>
      );
    }
  };

  return (
    <div className="breadcrumbMain">
      <div className="d-flex justify-content-start align-items-center">
        {/* {props.toggle ? (
          <img
            src={`${CDN_URL}partner_assets/control-center/sidebar.svg `}
            alt="sidebar"
            onClick={() => props.setToggle(!props.toggle)}
            style={{ marginRight: "10px" }}
          ></img>
        ) : null} */}
        <div>
          <Breadcrumb>
            {data && data.length > 0 ? (
              <>
                <NavLink to="/dashboard" className="breadcrumb-item">
                  Home
                </NavLink>
                 {data.map((item, index) => 
                  data[index-1] !== 'channel-mapping' && getBreadcrumb(item, index))
                 }
              </>
            ) : null}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
