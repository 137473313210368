import {DASHBOARD} from '../navigation/CONSTANTS';

export const BREADCRUMB_NAME = {
    "installers":{
        "installer-dashboard" : {name : 'Installer Dashboard', redirect: '' },
        "list" : {name : 'Installer List', redirect: DASHBOARD.INSTALLER_PRODUCT },
        'service' : { name :'Installer Service Area', redirect : '' },
        'hpwh-service' :{ name :'HPWH Service Area', redirect : '' }, 
        'template' : { name :'Template Mapping', redirect : '' },
        'history' :{ name :'History Logs', redirect : '' },
        'dashboard-approval' : { name :'Approval System', redirect : DASHBOARD.INSTALLER_APPROVAL_SYSTEM },
        'files' : { name :'Approval System', redirect : '' },
        'solar' : { name :'Solar', redirect : '' },
        'hpwh' : { name :'HPWH', redirect : '' },
        'battery' : { name :'Battery', redirect : '' },
        'inverter' : { name :'Inverter', redirect : '' },
        'smartPanel' : { name :'Approval System', redirect : '' },
        'evCharger' : { name :'Approval System', redirect : '' },
        'optimizer' : { name :'Approval System', redirect : '' }
    },
    "products" : {
        'editor' : { name :'Product Edior', redirect : '/dashboard/products/editor/hpwh' },
        'equipment': { name: 'Equipment', redirect : '/dashboard/products/equipment/solar'},
        'product-editor': { name: 'Product Editor', redirect : '/dashboard/products/editor/hpwh'},
        'add-product' : { name: 'Add', redirect : ''},
        'panel' : { name :'Solar', redirect : '/dashboard/products/editor/panel' },
        'solar' : { name :'Solar', redirect : '/dashboard/products/editor/panel' },
        'hpwh' : { name :'HPWH', redirect : '/dashboard/products/editor/panel' },
        'battery' : { name :'Battery', redirect : '/dashboard/products/editor/battery' },
        'inverter' : { name :'Inverter', redirect : '' },
        'smartPanel' : { name :'Approval System', redirect : '' },
        'evCharger' : { name :'Approval System', redirect : '' },
        'optimizer' : { name :'Approval System', redirect : '' },
        'manufacturer': { name :'Manufacturer List', redirect : DASHBOARD.PRODUCTS_MANUFACTURER_LIST },
        'add' : { name :'add', redirect : '' },
        'edit' : { name :'edit', redirect : '' }

    },
    "tools":{
        'downtime-config' : { name :'Downtime Configuration', redirect : '' },
        'downtime-config-review' : { name :'Downtime Configuration Review', redirect : '' },
        'downtime' : { name :'Downtime', redirect : '' },
        'product-config':{ name :'Product Configuration', redirect : '' },
        'partner-config' : { name :'Partner UtilityAPI', redirect : '' },
        'error-summary': { name: 'Error Summary', redirect : ''}, 
        'error-list': { name : 'Error List', redirect :''},
        'file-uploader': { name : 'File Uploader', redirect :''},
        'channel-mapping':{ name :'Channel Partner List', redirect : DASHBOARD.TOOLS_CHANNEL_MAPPING },
        'details':{ name :'Details', redirect : '' },
        'installer-mapping':{ name :'Installer Mapping', redirect : '' },
        'products':{ name :'Products', redirect : '' },


    },
    "users":{
        'list' : { name :'User List', redirect : '' },
        'roles' : { name :'Role Configurator', redirect : '' },
        'history' :{ name :'History Logs', redirect : '' },
    },
    "scheduler":{
        'staff': { name :'Staff Configuration', redirect : DASHBOARD.SCHEDULER_STAFF },
        'settings' : { name :'Global Settings', redirect : '/dashboard/scheduler/settings/display-times' },
        'staff-bulk' : { name :'Staff Bulk Edit', redirect : '/dashboard/scheduler/staff-bulks/partner'} ,
        'analysis' : { name :'Analysis', redirect : '' },
        'add' : { name :'User Profile', redirect : '' },
        'view' : { name :'User Profile', redirect : '' },
        'edit' : { name :'User Profile', redirect : '' },
        'staff-bulk-edit-event': { name : 'Staff Bulk Edit - Event', redirect : '/dashboard/scheduler/staff-bulks/event' },
        'staff-bulk-edit-partner': { name : 'Staff Bulk Edit - Channel Partner', redirect : '/dashboard/scheduler/staff-bulks/partner'},
        'staff-bulk-edit-region': { name : 'Staff Bulk Edit - Region', redirect : '/dashboard/scheduler/staff-bulks/region'},
        'display-times' : { name :'Display Times', redirect : '' },
        'regions' : { name :'Regions', redirect : '' },
        'availability' : { name :'Availability', redirect : '' },
        'roles' : { name :'Roles', redirect : '' },
        'blocker' : { name :'Blocke', redirect : '' },
        'events' : { name :'Events', redirect : '' },
        'partner' : { name :'Channel Partners', redirect : '' },
        'region' : { name :'Region', redirect : '' },
    },
    "projects":{
       'project-list' : { name :'Project List', redirect : DASHBOARD.PROJECT_LIST },
       'project-files-list' : { name: 'Project Files', redirect : ''},
        'equipment': { name: 'Equipment', redirect : ''},
        'detail': { name: 'Detail', redirect : ''},
        'tools': { name: 'Tools', redirect : ''},
        'files': { name: 'Project Files', redirect : ''},
        'project-list-new' : { name :'Project List (New)', redirect : DASHBOARD.PROJECT_LIST_NEW },
        

    },
    'contracts':{
        'templates' : { name :'Template List', redirect : DASHBOARD.CONTRACT_TEMPLATE_LIST },
        'templates-editor' : { name :'Editor', redirect : '' },
    }

}

export var ACTIVE_BREADCRUMB_NAME = '';
