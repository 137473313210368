import React, { useState, useEffect, useContext } from "react";
import { Container, Select, MenuItem } from "@mui/material";

import * as MUI from "../../../../../../utils/components/mui";
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Box } from "@mui/system";
import Grid from "../../../../../../layout/UI/dataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Button, showToast } from "../../../../../../layout";
import AddIcon from "@mui/icons-material/Add";

import { DashboardContext } from "../../../../../../services/dashboard/dashboardContext";
import {
  uploadHourlyProduction,
  upsertPanelGroup,
} from "../../../../../../services";
import IconButton from "@mui/material/IconButton";
import {useParams} from 'react-router-dom';

function Gridtable(props) {
  const context = useContext(DashboardContext);
  const { roof_type_list, panel_groups, design_ID, roof_id, checked } = props;
  const projectId = useParams();
  const [tableData, setTableData] = useState([]);
  const [disabled, setDisabled] = useState(false);

console.log('panelGroups',tableData)
  const disableColor = {
    color: "#AAA",
  };

  useEffect(() => {
    let data = [...panel_groups]
    setTableData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel_groups]);

  useEffect(() => {
    if (tableData?.length>0 && checked === 0 && roof_id !== undefined) {
      let updatedTableData = tableData.map((obj) => {
        return { ...obj, roof_type_id: roof_id?.id };
      });
      setTableData(updatedTableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, roof_id]);

  useEffect(() => {
    let yearlyproduction = tableData.reduce(
      (acc, item) => acc + item.yearly_production,
      0
    );
    context.set_total_target_yearly_production(yearlyproduction);
    context.setSystemDesign_roofpanel_values({
      ...context.state.systemDesign_roofpanel_values,
      yearly_production: yearlyproduction,
    });
    context.setRoofplane_deta(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const addRow = () => {
    let flag = 0;
    tableData.forEach((item) => {
      if (
        item.panel_count === null ||
        item.tilt === null ||
        item.azimuth === null ||
        item.tsrf === null ||
        item.tsrf === "" ||
        item.yearly_production === null ||
        item.roof_type_id === undefined ||
        item.roof_type_id === null
      ) {
        flag = 1;
      } else {
        flag = 0;
      }
    });
    if (flag === 1) {
      setDisabled(true);
      showToast("ERROR", "Please fill empty fields in the table");
    } else {
      setDisabled(true);
      upsertPanelGroup({ design_id: design_ID }).then((response) => {
        if (response.data.success) {
          if (checked === 0 && roof_id !== undefined) {
            let updatedTableData = response.data.data.map((obj) => {
              return { ...obj, roof_type_id: roof_id?.id };
            });

            setTableData(updatedTableData);
          } else {
            setTableData(response.data.data);
          }
          showToast("SUCCESS", "Panel added successfully");
        }
      });
    }
  };

  const removeRow = (id) => {
    setDisabled(false);
    let filteredTableData = tableData.filter((item) => item.id !== id);
    upsertPanelGroup({
      design_id: design_ID,
      panel_groups: filteredTableData,
    }).then((response) => {
      if (response.data.success) {
        setTableData(response.data.data);
        showToast("SUCCESS", "Panel removed successfully");
      }
    });
  };

  const submitPanelGroupData = () => {
    let flag = 0;
    tableData.forEach((item) => {
      if (
        item.panel_count === null ||
        item.tilt === null ||
        item.azimuth === null ||
        item.tsrf === null ||
        item.tsrf === "" ||
        item.yearly_production === null ||
        item.roof_type_id === undefined ||
        item.roof_type_id === null
      ) {

        console.log('flagPGitem',item)
        flag = 1;
      }
    });
    console.log('flagPG',flag)

    if (flag === 1) {
      setDisabled(true);
      showToast("ERROR", "Please fill empty fields in the table");
    } else {
      upsertPanelGroup({ design_id: design_ID, panel_groups: tableData }).then(
        (response) => {
          if (response.data.success) {
            setTableData(response.data.data);
            showToast("SUCCESS", "Data saved successfully");
            setDisabled(false);
          }
        }
      );
    }
  };

  const regexTest = (minVal, maxVal, value) => {
    let re = /^-?([0-9]{1,})$/;
    if (re.test(value)) {
      if (value > minVal && value < maxVal) {
        return 1;
      }
    } else {
      return 0;
    }
  };

  const changeHandler = (id, nam, value) => {
    const rowIndex = tableData.findIndex((row) => row.id === id);
    let newtableData = [...tableData];
    if (nam === "name") {
      newtableData[rowIndex]["name"] = value;
    }
    if (nam === "arrora_design_id") {
      newtableData[rowIndex]["arrora_design_id"] = value;
    }
    if (nam === "panel_count") {
      if (regexTest(0, 201, value) === 1) {
        newtableData[rowIndex]["panel_count"] = parseInt(value);
      } else {
        newtableData[rowIndex]["panel_count"] = null;
      }
    }
    if (nam === "tilt") {
      if (regexTest(0, 61, value) === 1) {
        newtableData[rowIndex]["tilt"] = value;
      } else {
        newtableData[rowIndex]["tilt"] = null;
      }
    }
    if (nam === "azimuth") {
      if (regexTest(0, 361, value) === 1) {
        newtableData[rowIndex]["azimuth"] = value;
      } else {
        newtableData[rowIndex]["azimuth"] = null;
      }
    }
    if (nam === "tsrf") {
      newtableData[rowIndex]["tsrf"] = value;
    }
    if (nam === "roof_type_id") {
      newtableData[rowIndex]["roof_type_id"] = value;
    }
    console.log("newtableData", newtableData);
    setTableData(newtableData);
  };

  const handleClicked = (event, key, rowId) => {
    let FileData = (event.target || event.srcElement).files[0];
    const imageData = new FormData();
    imageData.append(`myfile`, FileData);
    imageData.append("project_id", projectId.id);
    imageData.append("type", key);
    uploadHourlyProduction(imageData)
      .then((response) => {
        if (response.data.success) {
          const rowIndex = tableData.findIndex((row) => row.id === rowId);
          let newtableData = [...tableData];
          newtableData[rowIndex]["yearly_production"] =
            response.data.data.hourlyProductionData;
          newtableData[rowIndex]["project_file_id"] =
            response.data.data.project_file_id;
          newtableData[rowIndex]["original_name"] =
            response.data.data.original_name;
          if (checked === 0 && roof_id !== undefined) {
            let updatedTableData = newtableData.map((obj) => {
              return { ...obj, roof_type_id: roof_id?.id };
            });
            setTableData(updatedTableData);
          } else {
            setTableData(newtableData);
          }
        }
      })
      .catch((error) => {
        showToast("ERROR", error?.response?.data?.message);
      });
  };

  const getActions = (params) => {
    // const imageUrl = params.row.metadata.url; // decides to show view button
    const actions = [];
    actions.push(
      <GridActionsCellItem
        icon={
          <div>
            <input
              type="file"
              accept="*"
              style={{ display: "none" }}
              id={"image_link" + params.id}
              multiple
              onChange={(data) =>
                handleClicked(data, "designerPanelGroup", params.id)
              }
            />
            <MUI.Tooltip title="Files supported: CSV">
              <label
                htmlFor={"image_link" + params.id}
                style={{ marginLeft: "0px", color: "#fff" }}
              >
                <Button
                  variant="success"
                  buttonColor="primary"
                  component="span"
                  label="Choose"
                  styleClass="button-var2_header-grid"
                ></Button>
              </label>
            </MUI.Tooltip>
          </div>
        }
      />
    );
    return actions;
  };

  const columns = React.useMemo(
    () => [
      {
        field: "PanelGroup",
        className: "first-table",
        type: "actions",
        minWidth: 140,
        flex: 1,
        headerName: "Panel Group",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='bill-pdf'>{params.row.PanelGroup}</p>
          <input
            className="bill-pdf"
            type="text"
            value={params.row.name}
            onChange={(e) =>
              changeHandler(params.row.id, "name", e.target.value)
            }
          />
        ),
      },
      {
        field: "Dropdown_1",
        type: "actions",
        minWidth: 110,
        flex: 1,
        headerName: "Aurora Design",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          <div>
            <Select
              label=""
              className="aroraTypeSelect"
              value={params.row.arrora_design_id}
              onChange={(e) =>
                changeHandler(params.row.id, "arrora_design_id", e.target.value)
              }
              variant="outlined"
              disabled
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {roof_type_list?.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </div>
        ),
      },
      {
        field: "PanelCount",
        type: "actions",
        minWidth: 70,
        flex: 1,
        headerName: "Panel Count",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='panel-count'>{params.row.PanelCount}</p>
          <input
            className="panel-count"
            type="text"
            value={params.row.panel_count}
            onChange={(e) => {
              changeHandler(params.row.id, "panel_count", e.target.value);
            }}
          />
        ),
      },
      {
        field: "Tilt",
        type: "actions",
        minWidth: 70,
        flex: 1,
        headerName: "Tilt",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='Tilt'>{params.row.Tilt}</p>
          <input
            className="Tilt"
            type="text"
            value={params.row.tilt}
            onChange={(e) =>
              changeHandler(params.row.id, "tilt", e.target.value)
            }
          />
        ),
      },
      {
        field: "Azimuth",
        type: "actions",
        minWidth: 60,
        flex: 1,
        headerName: "Azimuth",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='Azimuth'>{params.row.Azimuth}</p>
          <input
            className="Azimuth"
            type="text"
            value={params.row.azimuth}
            onChange={(e) =>
              changeHandler(params.row.id, "azimuth", e.target.value)
            }
          />
        ),
      },
      {
        field: "TSRF",
        type: "actions",
        minWidth: 60,
        flex: 1,
        headerName: "TSRF",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='TSRF'>{params.row.TSRF}</p>
          <input
            className={
              params?.row?.tsrf !== null &&
              (params?.row?.tsrf < 40 || params?.row?.tsrf > 100)
                ? "errorHandling"
                : "TSRF"
            }
            style={{ outlineWidth: 0 }}
            type="number"
            value={params.row.tsrf}
            onChange={(e) => {
              changeHandler(params.row.id, "tsrf", e.target.value);
            }}
            onBlur={() => {
              if (params?.row?.tsrf <= 40 || params?.row?.tsrf >= 100) {
                let idx = params.api.getRowIndex(params.row.id);
                let newtableData = [...tableData];
                newtableData[idx]["tsrf"] = "";
                setTableData(newtableData);
              }
            }}
          />
        ),
      },
      {
        field: "YearlyProduction",
        type: "actions",
        minWidth: 100,
        flex: 1,
        headerName: "Yearly Production",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          // <p className='YearlyProduction'>{params.row.YearlyProduction}</p>
          <input
            className="YearlyProduction"
            value={params.row.yearly_production}
            type="text"
            disabled
          />
        ),
      },
      {
        field: "Roof Material",
        type: "actions",
        minWidth: 130,
        flex: 1,
        headerName: "Roof Material",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          <div>
            <Select
              className="roofTypeSelect"
              label=""
              disabled={checked === 0}
              value={params.row.roof_type_id}
              onChange={(e) =>
                changeHandler(params.row.id, "roof_type_id", e.target.value)
              }
              variant="outlined"
              IconComponent={ArrowDropDownIcon}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {roof_type_list?.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </div>
        ),
      },
      {
        field: "HourProduction",
        type: "actions",
        minWidth: 100,
        flex: 1,
        headerName: "Hour Production",
        cellClassName: "left-align",
        headerAlign: "left",
        renderCell: (params) => (
          <div
            style={{
              paddingLeft: "8px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {" "}
            <p
              style={{
                color: "#3688FD",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.row.original_name}
            </p>
          </div>
        ),
      },
      {
        field: "buttom",
        type: "actions",
        width: 100,
        headerName: "",
        getActions: (params) => getActions(params),
      },
      {
        field: "",
        type: "",
        width: 10,
        headerAlign: "left",
        sortable: false,
        cellClassName: "right-align",
        renderCell: (params) => {
          return (
            <div
              title="Click to remove row"
              onClick={() => removeRow(params.row.id)}
            >
              <i className="icon-close electrum-font"></i>
            </div>
          );
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData, changeHandler]
  );

  const CustomFooterStatusComponent = () => {
    return (
      <div className="footerBtn">
        <div>
          <IconButton
            aria-label="AddIcon"
            className="button"
            sx={{ padding: "0px" }}
            disabled={disabled}
            onClick={addRow}
          >
            <AddIcon
              sx={
                disabled
                  ? disableColor
                  : {
                      color: "#2266DD",
                      fontFamily: "ActualM",
                      fontWeight: "500",
                      fontSize: "14px",
                    }
              }
            />
            <span id="text_add">
              <h6
                style={
                  disabled
                    ? disableColor
                    : {
                        color: "#2266DD",
                        fontFamily: "ActualM",
                        fontWeight: "500",
                        fontSize: "14px",
                      }
                }
              >
                Add Panel Group
              </h6>
            </span>
          </IconButton>
        </div>
        <Button
          styleClass="button-var2_save_panels"
          variant="contained"
          buttonColor="success"
          label="Save Panel Group"
          handleClick={submitPanelGroupData}
        />
      </div>
    );
  };

  return (
    <div className="project-detail-files-component">
      <Container maxWidth="false">
        <div className="top-header"></div>
        <Box sx={{ width: "100%" }}>
          <Grid
            columns={columns}
            rows={tableData}
            autoHeight
            pagination={false}
            footer={CustomFooterStatusComponent}
          />
        </Box>
      </Container>
    </div>
  );
}

export default Gridtable;
