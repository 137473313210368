import React, { useState, useEffect } from "react";
import Grid from "../../../../../../layout/UI/dataGrid";

function GenabilityTable(props) {
  const [rowData, setRowData] = useState([]);
  const [kWh, setKwh] = useState("Term KWh");

  const { genabilityTableData } = props;

  useEffect(() => {
    if (genabilityTableData?.length > 0) {
      const data = [];
      let totalKWH = 0;
      let totalCost = 0;

      genabilityTableData.forEach((element, index) => {
        let itemValue = 0;
        if (kWh === "Daily KWh") {
          itemValue = (
            element.itemQuantity /
            Math.ceil(
              (new Date(element.toDateTime) - new Date(element.fromDateTime)) /
                (1000 * 60 * 60 * 24)
            )
          ).toFixed(2);
        } else {
          itemValue = element.itemQuantity?.toFixed(2);
        }
        totalKWH = parseFloat(totalKWH) + parseFloat(itemValue);
        totalCost = parseFloat(totalCost) + parseFloat(element.cost);
        let channel = { ...element, id: index + 1, value: itemValue };
        data.push(channel);
      });
      data.push({
        id: data.length + 1,
        cost: parseInt(totalCost)/12,
        rateAmount: 0.1867677,
        toDateTime: "",
        quantityKey: "",
        fromDateTime: "Total",
        itemQuantity: parseInt(totalKWH),
        value: parseFloat(totalKWH),
      });
      setRowData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genabilityTableData, kWh]);

  const handleMonthlyKwhChange = (e) => {
    setKwh(e.target.value);
  };

  const headerNameHandler = () => {
    return (
      <div className="kwhHeaderMain">
        <select
          className="kwhHeaderCell"
          value={kWh}
          onChange={(e) => handleMonthlyKwhChange(e)}
        >
          <option key="Daily KWh" value="Daily KWh">
            Daily kWh
          </option>
          <option key="Term KWh" value="Term KWh">
            Monthly kWh
          </option>
        </select>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        field: "fromDateTime",
        type: "string",
        width: 280,
        headerName: "Start Date",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => (
          <p>{params.row.fromDateTime.substring(0, 10)}</p>
        ),
      },
      {
        field: "toDateTime",
        type: "string",
        width: 270,
        headerName: "End Date",
        cellClassName: "left-align",
        headerAlign: "left",
        sortable: false,
        renderCell: (params) => (
          <p className="date">{params.row.toDateTime.substring(0, 10)}</p>
        ),
      },
      {
        field: "itemQuantity",
        type: "string",
        width: 270,
        gap: 12,
        headerName: headerNameHandler(),
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => (
          <p className="date">
            {" "}
            {params.row.value ? parseFloat(params.row.value).toFixed(2) : "-"}
          </p>
        ),
      },
      {
        field: "cost",
        type: "string",
        width: 250,
        headerName: "Rate ($)",
        headerAlign: "left",
        cellClassName: "left-align",
        sortable: false,
        renderCell: (params) => (
          <p className="merge-text">${params.row.cost?.toFixed(2)}</p>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[kWh, handleMonthlyKwhChange]
  );
  return (
    <div className="costSchedules">
      {rowData?.length > 0 ? (
        <Grid
          columns={columns}
          rows={rowData}
          autoHeight
          pagination={false}
          hideFooter
        />
      ) : (
        <div className="no-result">
          <p>No Data to display.</p>
        </div>
      )}
    </div>
  );
}

export default GenabilityTable;
