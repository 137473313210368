import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {ENGINE_URL, DOWNTIME_URL } from '../../CONSTANTS';
import {showToast} from '../../../utils/components/toast'

/**
 * @url -> /downtime-interface/control-center/store-downtime-configuration
 * @param {*} data 
 * @returns 
 */
export const scheduleMaintenance = async(data) => {
    return axiosInstance.post(ENGINE_URL + `downtime-interface/control-center/store-downtime-configuration`, data); 
}


/**
 * @url -> /downtime-interface/control-center/{channel_partner_name}/upload-files
 * @param {*} data 
 * @returns 
 */
 export const uploadDowntimeFile = async(data, key) => {
    return axiosInstance.post(ENGINE_URL + `downtime-interface/control-center/${key}/upload-files`, data);
}

//https://downtime-interface-prelive.electrum.co/downtime-interface/unplanned/26ca1d510124fa5d0daf33788681a597.json

export const getConfigs = async(type, key) => {
    return axiosInstance.get(DOWNTIME_URL+ `downtime-interface/${type}/${key}.json`); 
}

export const uploadDowntimeFile1 = async(data, key) => {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.post(ENGINE_URL + `downtime-interface/control-center/${key}/upload-files`, data)
            .then((response) => response.json())
            .then((res) => {
                if(res.data){
                    
                }
                resolve(res.data.data);
            }).catch ((err) => {
                showToast('ERROR' ,`Failed to upload File`)
                reject('userAuth error')
            });
 
    }
     catch (err) {
         resolve('system_error');
     }

     })   
}